import socket from '@mtt-nails/socket-events';
import { Box, CircularProgress } from '@mui/material';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuthStore, useShopStore } from '../providers/StoreProvider';
import * as paths from './paths';

const HEIGHT_LOADING = '100vh';

export const Socket: React.FC<{
  children: React.ReactNode;
  isLoading?: boolean;
  handleChange?: () => void;
}> = observer(({ children, isLoading, handleChange }) => {
  const authStore = useAuthStore();
  const shopStore = useShopStore();
  React.useEffect(() => {
    const initialSocket = autorun(async () => {
      await shopStore.fetch();
      if (shopStore.shopId) {
        socket.connect(authStore.authorization?.access_token || '');
        socket.joinRoom(shopStore.shopId);
        handleChange?.();
      } else socket.leave();
    });
    return () => {
      initialSocket();
    };
  }, []);

  if (!isLoading)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: HEIGHT_LOADING }}>
        <CircularProgress />
      </Box>
    );
  return <>{children}</>;
});

export const PrivateRoute: React.FC = observer(() => {
  const authStore = useAuthStore();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);

  const handleChange = React.useCallback(() => {
    setLoading(true);
  }, [loading]);

  if (authStore.isAuthenticated)
    return (
      <Socket handleChange={handleChange} isLoading={loading}>
        <Outlet />
      </Socket>
    );
  return <Navigate to={paths.login} state={location} replace />;
});
