import { Popover, PopoverOrigin } from '@mui/material';
import React from 'react';

interface IProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  children: React.ReactNode;
  vertical?: PopoverOrigin['vertical'];
  horizontal?: PopoverOrigin['horizontal'];
}

export const CustomPopover: React.FC<IProps> = ({
  anchorEl,
  handleClose,
  children,
  vertical = 'bottom',
  horizontal = 'left',
}) => {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
    >
      {children}
    </Popover>
  );
};
