import { Operator } from '@mtt-nails/apis/dist/v3';
import { Box, List as ListMui, ListItemButton, ListItemText, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: '39vh',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 5,
  padding: theme.spacing(2, 4),
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
}));

export const List = styled(ListMui)(({ theme }) => ({
  width: '100%',
  height: '30vh',
  overflow: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    backgroundColor: theme.palette.background.paper,
    width: 16,
  },
}));

export const ListItem = styled(ListItemButton)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  width: '100%',
  height: 40,
  backgroundColor: isSelected ? theme.palette.primary.main : `rgba(229,79,124,0.5)`,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  margin: theme.spacing(1, 0),
  '&:first-child': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const Text = styled(ListItemText)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.grey[900],
  justifyContent: 'center',
  fontSize: 16,
}));

interface IPaymentWattingList {
  listWaiting: Operator.Invoice[];
  currnetInvoiceWaiting: Operator.Invoice | null;
  onChangeInvoice?: (data: Operator.Invoice) => void;
}
const PaymentWaitingList: React.FC<IPaymentWattingList> = ({ listWaiting, onChangeInvoice, currnetInvoiceWaiting }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Typography fontSize={20} color={(theme) => theme.palette.grey[600]}>
        {t('payment.waiting.list')}
      </Typography>
      <List>
        {listWaiting.map((item) => {
          return (
            <ListItem
              isSelected={currnetInvoiceWaiting?.id === item.id}
              alignItems="center"
              key={item.id}
              onClick={() => onChangeInvoice?.(item)}
            >
              <Text>{dayjs(item.bookingNumber).format('HH:mm:ss')}</Text>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default React.memo(PaymentWaitingList);
