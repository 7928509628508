import { Box, Dialog, Grid, MenuItem, styled, TextField, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { Button } from '../../atom';

interface IProps {
  store: ModalStore;
  bookingStore: BookingStore;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  textAlign: 'center',
  backgroundColor: pink[300],
  color: theme.palette.primary.contrastText,
}));

export const TimeModal: React.FC<IProps> = observer(({ store, bookingStore }) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={store.toggleTimeModal} open={store.timeModal} fullWidth maxWidth="md">
      <Box>
        <WrapHeader>{t('dialog.booking-screen.time-booking')}</WrapHeader>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.date')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  select
                  value={
                    store.bookingHour ||
                    dayjs(get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)).format('HH:mm')
                  }
                  fullWidth
                  onChange={(e) => store.onChangeBookingHour(e.target.value)}
                >
                  {bookingStore.shopCol.map((option, idx) => (
                    <MenuItem key={idx} value={option.from}>
                      {option.from}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.time')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={t('dialog.new-booking.time')}
                    value={store.time || get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)}
                    disablePast
                    inputFormat="dd/MM/yyyy"
                    onChange={(e) => store.onChangeDate(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.confirm')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() =>
                      bookingStore.onConfirmEditTime(
                        dayjs(
                          store.time || get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null),
                        ).valueOf(),
                        store.bookingHour ||
                          dayjs(get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)).format('HH:mm'),
                        store.toggleTimeModal,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.cancel')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={store.toggleTimeModal}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
});
