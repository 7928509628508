import { Operator } from '@mtt-nails/apis/dist/v3';

export const convertCheckedInStaff = (data: Operator.Staff.PositionRelatedActivity[]) => {
  if (!data.length) return [];
  const _data = data.map((item) => {
    return {
      nickName: item.nickName,
      position: item.position,
      timeCheck: item.firstCheckinTime,
      userId: item.userId,
      point: (item as any)?.point,
      status: item.status,
    };
  });
  return _data;
};
