import { Box, Dialog, Grid, MenuItem, styled, TextField, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStore, formatDate, ModalStore } from '../../../pages/booking-page/store';
import { Button } from '../../atom';
import ListServicesCard from '../../molecules/ListServicesCard';

interface IProps {
  store: ModalStore;
  bookingStore: BookingStore;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  textAlign: 'center',
  backgroundColor: pink[300],
  color: theme.palette.primary.contrastText,
}));

const WrapService = styled(Box)(({ theme }) => ({
  minHeight: 46,
  padding: 10,
  border: `1px solid ${theme.palette.grey[100]}`,
}));

export const EditBookingModal: React.FC<IProps> = observer(({ store, bookingStore }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = autorun(() => {
      if (!isEmpty(bookingStore.selectedActivity)) {
        runInAction(() => {
          store.customer?.findCustomerById(bookingStore.selectedActivity.customerCode);
          store.updateSelectedServices(bookingStore.selectedActivity.serviceCategory);
        });
      }
    });
    return () => {
      fetch();
    };
  }, []);

  const onSubmit = () => {
    bookingStore.editConfirmedBooking({
      activityId: bookingStore.selectedActivity.id,
      expectedTime: formatDate(
        dayjs(get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)).valueOf(),
        store.bookingHour,
      ).format(),
      table: store.bookingTable,
      serviceCategories: store.chosenServices,
    });
  };

  return (
    <Dialog onClose={store.toggleEditModal} open={store.editModal} fullWidth maxWidth="md">
      <Box>
        <WrapHeader>{t('dialog.new-booking.edit-booking')}</WrapHeader>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.date')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {dayjs(get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.time')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  select
                  value={
                    store.bookingHour ||
                    dayjs(get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)).format('HH:mm')
                  }
                  fullWidth
                  onChange={(e) => store.onChangeBookingHour(e.target.value)}
                >
                  {bookingStore.shopCol.map((option, idx) => (
                    <MenuItem key={idx} value={option.from}>
                      {option.from}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.customer-code')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{get(bookingStore.selectedActivity, 'customerCode', '')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.table')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  select
                  value={store.bookingTable || get(bookingStore.selectedActivity, 'staff.table', '')}
                  fullWidth
                  onChange={(e) => store.onChangeTable(+e.target.value)}
                >
                  {bookingStore.staffCols.map((option, idx) => (
                    <MenuItem key={idx} value={option.staff.table}>
                      {option.staff.table}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.name')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{store.customer?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <WrapService>
              <Typography>{t('dialog.new-booking.services')}</Typography>
              <ListServicesCard services={store.services} />
            </WrapService>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.confirm')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.cancel')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={store.toggleEditModal}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
});
