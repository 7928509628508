import { Operator } from '@mtt-nails/apis/dist/v3';

import { SubCategory } from '../../pages/home/store';

export const transformService = (list: Operator.Service.GroupCategory[]) => {
  const data: SubCategory[] = [];
  list.map(({ items }) => {
    items.map(({ id, image, name }) => {
      data.push(new SubCategory({ id, name, image }));
    });
  });
  return data;
};
