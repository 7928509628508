import { Box, Grid, styled, Typography as TypographyMui } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atom';

const Wrapper = styled(Box)({
  width: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 36,
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, rgb(229, 79, 124, 0.5))`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.grey[50],
}));

const Typography = styled(TypographyMui)(() => ({
  fontSize: 20,
}));

interface IChangeCodeForm {
  customerName: string;
  customerCode: string;
  handleConfirmChangeCode: () => void;
  onCancel: () => void;
}

const ChangeCodeForm: React.FC<IChangeCodeForm> = observer(
  ({ customerName, customerCode, handleConfirmChangeCode, onCancel }) => {
    const { t } = useTranslation();

    return (
      <Wrapper>
        <Header>{t('component.organisms.change-code-form.header')}</Header>
        <Box p={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography>{t('component.organisms.change-code-form.title')}</Typography>
            <Typography>
              {customerName} - {customerCode}
            </Typography>
          </Box>
          <Box mt={10} width="100%">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  label={t('component.organisms.change-table-form.button.confirm')}
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleConfirmChangeCode}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  label={t('component.organisms.change-table-form.button.close')}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Wrapper>
    );
  },
);

export default ChangeCodeForm;
