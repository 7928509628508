import { Box, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ListItem = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '94vh',
  backgroundColor: theme.palette.primary.main,
}));

const Item = styled(Box)<{ isChoose?: boolean }>(({ theme, isChoose }) => ({
  width: '100%',
  height: '56px',
  backgroundColor: !isChoose ? theme.palette.primary.main : theme.palette.primary.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all ease 0.5s',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

interface IMenuItemProps {
  // label: string;
  title: string;
  isChoose?: boolean;
  onClick?: () => void;
}

const MenuItem: React.FC<IMenuItemProps> = ({ title, onClick }) => {
  const { t } = useTranslation();
  return (
    <Item onClick={onClick}>
      <Typography variant="h6" color="white">
        {t(`${title}`)}
      </Typography>
    </Item>
  );
};

interface IMenuSettingProps {
  listMenuItems?: IMenuItemProps[];
}

export const MenuSetting: React.FC<IMenuSettingProps> = ({ listMenuItems }) => {
  return (
    <ListItem>
      {listMenuItems?.map((item, index) => (
        <Box sx={{ width: '100%' }} key={index}>
          <MenuItem title={item.title} isChoose={item.isChoose} onClick={item.onClick} />
        </Box>
      ))}
    </ListItem>
  );
};
