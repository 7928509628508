import { Box, Dialog, Grid, styled, TextField } from '@mui/material';
import { pink } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { Button } from '../../atom';

interface IProps {
  store: ModalStore;
  bookingStore: BookingStore;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  textAlign: 'center',
  backgroundColor: pink[300],
  color: theme.palette.primary.contrastText,
}));

export const MessageModal: React.FC<IProps> = observer(({ store, bookingStore }) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={store.toggleMessageModal} open={store.messageModal} fullWidth maxWidth="md">
      <Box>
        <WrapHeader>{t('dialog.booking-screen.cancel-booking')}</WrapHeader>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12}>
            <TextField
              placeholder={t('dialog.new-booking.placeholder')}
              multiline
              rows={8}
              fullWidth
              required
              value={store.comment}
              onChange={(e) => store.handleChangeComment(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.confirm')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => bookingStore.onConfirmMessage(store.comment, store.toggleMessageModal)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.cancel')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={store.toggleMessageModal}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
});
