import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import React from 'react';

import { theme } from '../../../libs/themes';

export interface IInputSearchProps {
  onSearch: (val: string) => void;
}

const Input = styled(InputBase)(() => ({
  width: '100%',
  padding: '16px 4px',
  borderRadius: 16,
  background: theme.palette.grey[100],
}));

export const InputSearch: React.FC<IInputSearchProps> = ({ onSearch }) => {
  return <Input onChange={(e) => onSearch(e.target.value)} />;
};
