import { Box, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { ShopStore } from '../../../store/domains/ShopStore';
import { BookingTable } from '../../molecules';
import { LeftPanelBooking } from '../../organisms/left-panel-booking';

export interface IBookingScreenProps {
  time: Date;
  bookingStore: BookingStore;
  modalStore: ModalStore;
  shopStore: ShopStore;
}

export const BookingScreen: React.FC<IBookingScreenProps> = observer(
  ({ time, modalStore, shopStore, bookingStore }) => {
    const { t } = useTranslation();

    return (
      <Box>
        <LeftPanelBooking bookingStore={bookingStore} modalStore={modalStore}>
          <Box paddingX="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center" pb={5}>
              <Typography>{`${t('label.timeline')} ${dayjs(time).format('DD/MM/YYYY')}`}</Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box mr={2}>
                  <TextField
                    label={t('dialog.booking-screen.search')}
                    value={bookingStore.searchValue}
                    onChange={(e) => bookingStore.changeSearch(e.target.value)}
                  />
                </Box>
                <Box ml={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={t('dialog.new-booking.time')}
                      value={bookingStore.selectedDate}
                      disablePast
                      inputFormat="dd/MM/yyyy"
                      onChange={(e) => bookingStore.changeDate(e || new Date())}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
            <BookingTable
              modalStore={modalStore}
              rows={bookingStore.displayStaffCols}
              timeRange={bookingStore.shopCol}
              nameOwner={shopStore.name || ''}
              handleOpenMenuTrigger={bookingStore.handleOpenMenuTrigger}
            />
          </Box>
        </LeftPanelBooking>
      </Box>
    );
  },
);
