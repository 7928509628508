import { Operator } from '@mtt-nails/apis/dist/v3';
import { Box, Grid, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atom';
import { IInvoiceDiscount, IInvoiceServiceLine, IPrice, IServiceDetail } from '../../molecules';
import InvoiceDetailList from '../../organisms/invoice-detail-list';
import ListServiceDetails from '../../organisms/list-service-details';
import PaymentWaitingList from '../../organisms/payment-waiting-list';

export const BoxListServiceDetails = styled(Box)(({ theme }) => ({
  height: '75vh',
  overflow: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    backgroundColor: theme.palette.grey[100],
    width: 16,
  },
}));

export interface IPayment {
  listServiceDetails: IServiceDetail[];
  invoiceList: IInvoiceServiceLine[];
  invoiceDiscound: IInvoiceDiscount;
  total: IPrice;
  listWaiting: Operator.Invoice[];
  currnetInvoiceWaiting: Operator.Invoice | null;
  onChangeInvoice?: (data: Operator.Invoice) => void;
  onPromoClick?: () => void;
  onOfferClick?: () => void;
  onCheckout?: () => void;
}

export const Payment: React.FC<IPayment> = ({
  invoiceList,
  listServiceDetails,
  invoiceDiscound,
  total,
  listWaiting,
  currnetInvoiceWaiting,
  onPromoClick,
  onOfferClick,
  onCheckout,
  onChangeInvoice,
}) => {
  const { t } = useTranslation();
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <BoxListServiceDetails>
            <ListServiceDetails listServiceDetails={listServiceDetails} />
          </BoxListServiceDetails>
          <Box mt={2}>
            <Grid item container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button label={t('payment.promo')} variant="contained" background="primary" onClick={onPromoClick} />
              </Grid>
              <Grid item>
                <Button
                  label={t('payment.offer')}
                  variant="contained"
                  color="secondary"
                  background="secondary"
                  onClick={onOfferClick}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <InvoiceDetailList
            invoiceList={invoiceList}
            invoiceDiscound={invoiceDiscound}
            total={total}
            onCheckout={onCheckout}
          />
          <Box mt={2} />
          <PaymentWaitingList
            listWaiting={listWaiting}
            currnetInvoiceWaiting={currnetInvoiceWaiting}
            onChangeInvoice={onChangeInvoice}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Payment;
