import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BoxProps, FormControlLabel, FormGroup, styled, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translate } from '../../../libs/utils/translate';
import { Button, Input } from '../../atom';

const WrapForm = styled(FormGroup)<BoxProps>(() => ({
  width: '100%',
}));

const WrapFormInput = styled(Box)(() => ({
  width: '530px',
  marginBottom: 32,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const WrapInput = styled(Box)(() => ({
  width: '60%',
}));

interface IChangePasswordProps {
  oldPassword?: string;
  onChangeOldPassword?: (oldPassword: string) => void;
  newPassword?: string;
  onChangeNewPassword?: (newPassword: string) => void;
  confirmPassword?: string;
  onChangeConfirmPassword?: (confirmPassword: string) => void;
  onChangePassword?: (oldPassword: string, newPassword: string, confirmPassword: string) => void;
}

interface IFormChangePasswordProps {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  oldPassword: yup.string().required(
    translate('validations.required', {
      field: translate('fields.password'),
    }),
  ),
  newPassword: yup.string().required(
    translate('validations.required', {
      field: translate('fields.password'),
    }),
  ),
  confirmPassword: yup.string().required(
    translate('validations.required', {
      field: translate('fields.password'),
    }),
  ),
});

export const ChangePassword: React.FC<IChangePasswordProps> = ({
  oldPassword,
  confirmPassword,
  newPassword,
  onChangePassword,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const defaultValues = {
    oldPassword: oldPassword || '',
    newPassword: newPassword || '',
    confirmPassword: confirmPassword || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormChangePasswordProps>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleOnSubmit = ({ oldPassword, newPassword, confirmPassword }: IFormChangePasswordProps) => {
    if (oldPassword && newPassword && confirmPassword && onChangePassword)
      onChangePassword(oldPassword, newPassword, confirmPassword);
  };

  const oldPassword_ = React.useMemo(() => register('oldPassword'), [register]);
  const newPassword_ = React.useMemo(() => register('newPassword'), [register]);
  const confirmPassword_ = React.useMemo(() => register('confirmPassword'), [register]);

  return (
    <WrapForm>
      <form
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <WrapFormInput>
          <Typography variant="subtitle1">{t('setting.change-password.old-password')}</Typography>
          <WrapInput>
            <Input
              type={showPassword ? 'text' : 'password'}
              {...oldPassword_}
              placeholder={t('setting.change-password.old-password')}
              fullWidth
              errorMessage={errors.oldPassword?.message}
            />
          </WrapInput>
        </WrapFormInput>
        <WrapFormInput>
          <Typography variant="subtitle1">{t('setting.change-password.new-password')}</Typography>
          <WrapInput>
            <Input
              type={showPassword ? 'text' : 'password'}
              {...newPassword_}
              placeholder={t('setting.change-password.new-password')}
              fullWidth
              errorMessage={errors.newPassword?.message}
            />
          </WrapInput>
        </WrapFormInput>
        <WrapFormInput>
          <Typography variant="subtitle1">{t('setting.change-password.confirm-password')}</Typography>
          <WrapInput>
            <Input
              type={showPassword ? 'text' : 'password'}
              {...confirmPassword_}
              placeholder={t('setting.change-password.confirm-password')}
              fullWidth
              errorMessage={errors.confirmPassword?.message}
            />
          </WrapInput>
        </WrapFormInput>
        <WrapFormInput>
          <FormControlLabel
            control={<Checkbox defaultChecked onChange={handleShowPassword} checked={showPassword} />}
            label={t('setting.change-password.show-password')}
          />
          <Button
            label={t('components.organisms.item-serving-customer.finish-button')}
            color="primary"
            type="submit"
            variant="contained"
            onClick={handleSubmit(handleOnSubmit)}
          />
        </WrapFormInput>
      </form>
    </WrapForm>
  );
};
