import { Box, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atom';
import { IPromotionItemProps, PromoItem } from '../../molecules/promo-item';

const WrapListPromotion = styled(Box)(() => ({
  width: '100%',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderPromotion = styled(Box)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light} , ${theme.palette.primary.main})`,
}));

const BodyListPromotions = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '400px',
  overflowY: 'hidden',
  boxSizing: 'border-box',
  padding: theme.spacing(8),
  backgroundColor: 'white',
  transition: 'all ease 0.5s',
  '&:hover': {
    overflowY: 'auto',
  },
}));

const FooterListPromotions = styled(Box)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface IListPromotionsProps {
  listPromotions?: IPromotionItemProps[];
  onRemoveClick?: () => void;
}

export const ListPromotions: React.FC<IListPromotionsProps> = ({ listPromotions, onRemoveClick }) => {
  const { t } = useTranslation();

  return (
    <WrapListPromotion>
      <HeaderPromotion>
        <Typography variant="h5" color="white">
          {t('payment.promotion.your-promotions')}
        </Typography>
      </HeaderPromotion>
      <BodyListPromotions>
        {listPromotions ? (
          listPromotions.map((item) => (
            <Box key={item.id} mb={2}>
              <PromoItem {...item} />
            </Box>
          ))
        ) : (
          <Typography variant="h4" align="center">
            {t('payment.promotion.empty')}
          </Typography>
        )}
      </BodyListPromotions>
      <FooterListPromotions>
        <Button
          label={t('payment.promotion.remove-promotions')}
          color="primary"
          onClick={onRemoveClick}
          type="button"
          variant="contained"
        />
      </FooterListPromotions>
    </WrapListPromotion>
  );
};
