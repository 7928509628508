import { operatorApi } from '@mtt-nails/apis/dist/v3';
import { makeAutoObservable, runInAction } from 'mobx';

import { AuthStore } from '../../store/domains/AuthStore';

class User {
  username = '';
  password = '';

  constructor() {
    makeAutoObservable(this);
  }

  setUsername = (username: string) => (this.username = username);
  setPassword = (password: string) => (this.password = password);
}

export class Store {
  user: User;
  isSubmitting = false;

  constructor(private authStore: AuthStore) {
    makeAutoObservable(this);
    this.user = new User();
  }

  login = async (username: string, password: string) => {
    try {
      this.isSubmitting = true;
      const { data } = await operatorApi.authenticate({ username, password });
      this.authStore.setAuthorization(data);
    } catch (error) {
      this.authStore.root.appStore.notification?.setError(error);
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  };
}
