import { operatorApi } from '@mtt-nails/apis/dist/v3';
import { makeAutoObservable } from 'mobx';

import { Authorization } from '../objects/Authorization';
import { RootStore } from '../RootStore';

export class AuthStore {
  authorization?: Authorization | null;
  interceptor?: number;

  constructor(public root: RootStore) {
    makeAutoObservable(this);
    this.loadAuth();
  }

  setAuthorization(authorization: Authorization, save = true) {
    this.authorization = authorization;
    operatorApi.authorizeApi(authorization);
    if (save) {
      this.root.storageStore.saveToken(JSON.stringify(authorization));
    }
  }

  get isAuthenticated() {
    return !!this.authorization;
  }

  loadAuth() {
    const token = this.root.storageStore.getToken();
    if (!token) return;
    try {
      const authorization = JSON.parse(token);
      this.setAuthorization(authorization, false);
    } catch (error) {
      this.root.appStore.notification?.setError(error);
    }
  }

  deAuthorize() {
    this.root.storageStore.clear();
    operatorApi.deauthorizeApi();
    this.eject();
    this.authorization = null;
  }

  inject() {
    this.interceptor = operatorApi.inject.response((error) => {
      if (error && error.response && error.response.status === 401) {
        this.deAuthorize();
      }
    });
  }
  eject() {
    if (this.interceptor !== undefined) {
      operatorApi.eject.response(this.interceptor);
    }
  }
}
