import { yupResolver } from '@hookform/resolvers/yup';
import GroupIcon from '@mui/icons-material/Group';
import KeyIcon from '@mui/icons-material/Key';
import Login from '@mui/icons-material/Login';
import { Box, styled } from '@mui/material';
import { BoxProps } from '@mui/system';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translate } from '../../../libs/utils/translate';
import { Button, Input } from '../../atom';

interface ILoginFormProps {
  username?: string;
  password?: string;
  onUsernameChange?: (username: string) => void;
  onPasswordChange?: (password: string) => void;
  onLogin?: (username: string, password: string) => void;
}

interface IFormLogin {
  username: string;
  password: string;
}

const schema = yup.object().shape({
  username: yup.string().required(
    translate('validations.required', {
      field: translate('fields.username'),
    }),
  ),
  password: yup.string().required(
    translate('validations.required', {
      field: translate('fields.password'),
    }),
  ),
});

const BoxTitle = styled(Box)<BoxProps>(({ theme }) => ({
  boxShadow: `1px 4px ${theme.palette.grey[500]}`,
  padding: '12px',
  fontWeight: 'bold',
}));

export const LoginForm: React.FC<ILoginFormProps> = ({ username, password, onLogin }) => {
  const { t } = useTranslation();

  const defaultValues = {
    username: username || '',
    password: password || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormLogin>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const username_ = React.useMemo(() => register('username'), [register]);
  const password_ = React.useMemo(() => register('password'), [register]);

  const onSubmitLogin = ({ username, password }: IFormLogin) => {
    if (username && password && onLogin) onLogin(username, password);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitLogin)}>
      <BoxTitle>{t('form.login')}</BoxTitle>
      <Box sx={{}}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
          <Box sx={{ width: '30%' }}>
            <Input
              fullWidth
              placeholder={t('fields.username')}
              {...username_}
              frontIcon={<GroupIcon />}
              errorMessage={errors.username?.message}
              inputProps={{ tabIndex: 1 }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
          <Box sx={{ width: '30%' }}>
            <Input
              fullWidth
              type="password"
              placeholder={t('fields.password')}
              {...password_}
              frontIcon={<KeyIcon />}
              errorMessage={errors.password?.message}
              inputProps={{ tabIndex: 2 }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 4 }}>
          <Box sx={{ width: '20%' }}>
            <Button
              fullWidth
              type="submit"
              startIcon={<Login />}
              label={t('form.login')}
              variant="contained"
              background="linear-gradient(90deg, rgba(12,0,36,1) 0%, rgba(60,9,121,0.742734593837535) 35%, rgba(218,0,255,1) 100%)"
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};
