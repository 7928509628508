import { Box, Container, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useAuthStore, useShopStore } from '../../../providers/StoreProvider';
import Drawer from '../../molecules/drawer';
import Header from '../../molecules/header';
import { menu } from './navigates';

const { innerHeight } = window;

const Wrapper = styled(Box)<{ hasContainer?: boolean }>(({ theme, hasContainer }) => ({
  backgroundColor: theme.palette.grey[100],
  minHeight: innerHeight - 64,
  padding: !hasContainer ? theme.spacing(3, 0) : 0,
}));

interface ILayoutProps {
  children: React.ReactNode;
  hasContainer?: boolean;
  title?: string;
}
const Layout: React.FC<ILayoutProps> = observer(({ children, hasContainer, title }) => {
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const authStore = useAuthStore();
  const shopStore = useShopStore();

  useEffect(() => {
    if (title) document.title = title;
  });

  const handleLogout = () => {
    authStore.deAuthorize();
  };
  return (
    <>
      <Header
        phoneNumber={shopStore.contact?.phone.number}
        onMenuClick={() => setIsMenu(true)}
        shopName={shopStore.name}
      />
      <Drawer
        open={isMenu}
        onClose={() => setIsMenu(false)}
        menuList={menu}
        imageLink={shopStore.banners?.[0] || ''}
        onLogout={handleLogout}
      />
      <Wrapper hasContainer={hasContainer}>
        {!hasContainer ? <Container>{children}</Container> : <>{children}</>}
      </Wrapper>
    </>
  );
});

export default Layout;
