import { StaffActivitiesStatus } from '@mtt-nails/consts';
import DoneIcon from '@mui/icons-material/Done';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { timeConverter } from '../../../libs/utils/convertTime';
import { OrderIndicator } from '../../atom/order-indicator';

export interface IStaffCheckedInItemProps {
  nickName: string;
  timeCheck: Date;
  point?: number;
  position?: number;
  status: string;
  userId?: string;
  onCheckout?: () => void;
}

const WrapBox = styled(Box)<{ status: string }>(({ theme, status }) => ({
  display: 'flex',
  padding: theme.spacing(1.5, 2),
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(1.25),
  background:
    status === StaffActivitiesStatus.Doing
      ? theme.palette.error.light
      : status === StaffActivitiesStatus.Ready
      ? theme.palette.info.light
      : theme.palette.success.light,
}));

const WrapCenterBox = styled(Box)(() => ({
  textAlign: 'center',
}));

const StaffTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const StaffCheckout = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const WrapTickIcon = styled(DoneIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const WrapCheckoutIcon = styled(LogoutIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  cursor: 'pointer',
}));

export const StaffCheckedInItem: React.FC<IStaffCheckedInItemProps> = ({
  nickName,
  position,
  status,
  point = 0,
  timeCheck,
  onCheckout,
}) => {
  const { t } = useTranslation();

  return (
    <WrapBox status={status}>
      <Box width="25%">
        <StaffTypo>{nickName}</StaffTypo>
      </Box>
      <WrapCenterBox width="15%">
        <StaffTypo>{point}</StaffTypo>
      </WrapCenterBox>
      <WrapCenterBox width="30%">
        <OrderIndicator position={position} status={status} />
      </WrapCenterBox>
      <WrapCenterBox width="20%">
        <StaffTypo>{t('staff.time.check', { time: timeConverter.formatNumber(timeCheck) })}</StaffTypo>
        <WrapTickIcon />
      </WrapCenterBox>
      <WrapCenterBox width="10%">
        <StaffCheckout>{t('staff.checkout')}</StaffCheckout>
        <WrapCheckoutIcon onClick={onCheckout} />
      </WrapCenterBox>
    </WrapBox>
  );
};
