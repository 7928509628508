import { Box, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atom';
import { IInvoiceDetailProps, InvoiceDetail } from '../../molecules/invoice-detail';

const WrapInvoice = styled(Box)(() => ({
  width: '100%',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderInvoice = styled(Box)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light} , ${theme.palette.primary.main})`,
}));

const BodyInvoice = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '500px',
  overflowY: 'hidden',
  boxSizing: 'border-box',
  padding: theme.spacing(4),
  backgroundColor: 'white',
  transition: 'all ease 0.5s',
  '&:hover': {
    overflowY: 'auto',
  },
}));

const FooterInvoice = styled(Box)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface IInvoiceProps {
  invoiceDetails: IInvoiceDetailProps;
  onPaymentClick?: () => void;
}

export const Invoices: React.FC<IInvoiceProps> = ({ invoiceDetails, onPaymentClick }) => {
  const { t } = useTranslation();
  return (
    <WrapInvoice>
      <HeaderInvoice>
        <Typography variant="h5" color="white">
          {t('payment.invoice.invoice')}
        </Typography>
      </HeaderInvoice>
      <BodyInvoice>
        <InvoiceDetail {...invoiceDetails} />
      </BodyInvoice>
      <FooterInvoice>
        <Button
          label={t('payment.invoice.paymant')}
          color="primary"
          onClick={onPaymentClick}
          type="button"
          variant="contained"
        />
      </FooterInvoice>
    </WrapInvoice>
  );
};
