import { Box } from '@mui/material';
import { autorun } from 'mobx';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loading } from '../../components/atom';
import { LoginForm } from '../../components/organisms';
import { useAuthStore } from '../../providers/StoreProvider';
import { paths } from '../../routers';
import { Store } from './store';

export const LoginPage: React.FC = () => {
  const authStore = useAuthStore();
  const store = useLocalObservable(() => new Store(authStore));
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('page.login.title');
  });

  useEffect(
    () =>
      autorun(() => {
        if (authStore.isAuthenticated) {
          navigate(paths.home);
        }
      }),
    [],
  );

  return (
    <Box>
      <Observer>
        {() => (
          <LoginForm
            username={store.user.username}
            password={store.user.password}
            onUsernameChange={store.user.setUsername}
            onPasswordChange={store.user.setPassword}
            onLogin={store.login}
          />
        )}
      </Observer>
      <Observer>
        {() => {
          return store.isSubmitting ? <Loading /> : null;
        }}
      </Observer>
    </Box>
  );
};
