import EditSharpIcon from '@mui/icons-material/EditSharp';
import { Box, styled, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISummaryRegisterCustomer {
  fullName: string;
  phoneNumber: string;
  email: string;
  birthday: string;
  onHandleStep?: (step: number) => void;
  displayEditIcons?: boolean;
  customerCode?: number;
}
const BoxWrap = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 20,
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: 10,
}));

const SubBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 10,
  marginBottom: 10,
  '&>*:first-children': {
    marginTop: 0,
  },
  '&>*:last-children': {
    marginBottom: 0,
  },
}));

export const SummaryRegisterCustomer: React.FC<ISummaryRegisterCustomer> = ({
  customerCode,
  fullName,
  phoneNumber,
  email,
  birthday,
  onHandleStep,
  displayEditIcons = false,
}) => {
  const { t } = useTranslation();
  const info = [
    { title: t('register.form.customer-code'), in4: customerCode || '' },
    { title: t('register.form.name'), in4: fullName },
    { title: t('register.form.phone-number'), in4: phoneNumber },
    { title: t('register.form.email'), in4: email },
    { title: t('register.form.birthday'), in4: birthday },
  ];
  return (
    <BoxWrap>
      {info.map((info, index) => {
        return (
          info.in4 && (
            <SubBox key={index}>
              <Box>
                <Typography sx={{ display: 'inline' }}>{info.title}: </Typography>
                <Typography sx={{ display: 'inline' }}>{info.in4}</Typography>
              </Box>
              {displayEditIcons && (
                <Box>
                  <EditSharpIcon sx={{ cursor: 'pointer' }} onClick={() => onHandleStep?.(index)} />
                </Box>
              )}
            </SubBox>
          )
        );
      })}
    </BoxWrap>
  );
};
