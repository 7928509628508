import { Observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/templates/Layout';
import { Settings } from '../../components/templates/setting';
import { useAuthStore, useStorageStore } from '../../providers/StoreProvider';
import { Store } from './store';

export const SettingPage: React.FC = () => {
  const { t } = useTranslation();
  const authStore = useAuthStore();
  const storageStore = useStorageStore();
  const store = useLocalObservable(() => new Store(authStore, storageStore));

  return (
    <Layout hasContainer title={t('menu.title.settings')}>
      <Observer>
        {() => (
          <Settings
            oldPassword={store.changePassword.oldPassword}
            newPassword={store.changePassword.newPassword}
            confirmPassword={store.changePassword.confirmPassword}
            onChangeOldPassword={store.changePassword.setOldPassword}
            onChangeNewPassword={store.changePassword.setNewPassword}
            onChangeConfirmPassword={store.changePassword.setConfirmPassword}
            onChangePassword={store.submit}
            changeLanguage={store.changeLanguage}
            defaultLanguage={store.getDefaultLanguage}
          />
        )}
      </Observer>
    </Layout>
  );
};
