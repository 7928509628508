import { operatorApi } from '@mtt-nails/apis/dist/v3';
import socket from '@mtt-nails/socket-events';
import axios from 'axios';

import { API_URI, SOCKET_URI } from '../libs/constants';
import { AppStore } from './domains/AppStore';
import { AuthStore } from './domains/AuthStore';
import { ShopStore } from './domains/ShopStore';
import { StaffStore } from './domains/StaffStore';
import { StorageStore } from './domains/StorageStore';

export interface IRootStore {
  appStore: AppStore;
  storageStore: StorageStore;
  authStore: AuthStore;
  shopStore: ShopStore;
  staffStore: StaffStore;
}

export class RootStore implements IRootStore {
  appStore: AppStore;
  storageStore: StorageStore;
  authStore: AuthStore;
  shopStore: ShopStore;
  staffStore: StaffStore;

  constructor() {
    this.init();
    this.appStore = new AppStore(this);
    this.storageStore = new StorageStore(this);
    this.authStore = new AuthStore(this);
    this.shopStore = new ShopStore(this);
    this.staffStore = new StaffStore(this);
  }

  init() {
    const instance = axios.create({
      baseURL: API_URI,
      timeout: 1000 * 60,
    });
    operatorApi.setInstance(instance);

    socket.setSocketServer(SOCKET_URI);
    (socket as any).setOptions({ path: undefined });
  }
}
