import { operatorApi } from '@mtt-nails/apis/dist/v3';
import { makeAutoObservable, runInAction } from 'mobx';

import { AppStore } from './AppStore';

export class Customer {
  shopId: string;
  id?: string;
  name?: string;
  customerCode?: number;
  showMissingCustomerCodeWarning? = false;

  constructor(private appStore: AppStore, shopId: string) {
    makeAutoObservable(this);
    this.shopId = shopId;
  }

  findCustomerById = async (customerCode: string) => {
    try {
      const { data } = await operatorApi.getCustomerByCode({
        shopId: this.shopId,
        customerCode: Number(customerCode),
      });

      runInAction(() => {
        this.name = data.name;
        this.id = data.id;
        this.customerCode = data.customerCode;
      });
    } catch (error: any) {
      this.id = undefined;
      this.name = undefined;
      this.customerCode = undefined;
      this.showMissingCustomerCodeWarning = true;
      this.appStore.notification?.setError(error);
    }
  };

  reset = () => {
    this.id = undefined;
    this.name = undefined;
    this.customerCode = undefined;
    this.showMissingCustomerCodeWarning = false;
  };
}
