import EditIcon from '@mui/icons-material/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { grey, pink } from '@mui/material/colors';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { theme } from '../../../libs/themes';
import { Button } from '../../atom';

export interface ICardBookingProps {
  id: string;
  src: string;
  name: string;
  message?: string;
  phone: string;
  time: Date;
  staff?: any;
  onConfirm?: () => void;
  onReject?: () => void;
  lastItem?: boolean;
  onEditTime?: () => void;
  onEditStaff?: () => void;
}

export const CardBooking: React.FC<ICardBookingProps> = ({
  src,
  name,
  phone,
  time,
  staff,
  onConfirm,
  onReject,
  lastItem = false,
  onEditTime,
  onEditStaff,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          borderRadius: '12px',
          background: theme.palette.common.white,
          color: theme.palette.common.black,
          marginBottom: lastItem ? 0 : 2,
        }}
      >
        <Box display="flex" alignItems="center" padding="16px" borderBottom={`1px solid ${theme.palette.grey[100]}`}>
          <Avatar alt="MU FC" src={src} />
          <Box display="flex" paddingLeft={3} flexDirection="column">
            <Typography fontWeight={550}>{name}</Typography>
            <Typography fontWeight={550}>{phone}</Typography>
          </Box>
        </Box>
        <Box paddingTop={1} padding="16px 10px">
          <EditLine icon={<PendingActionsIcon fontSize="large" />} onClickEdit={() => onEditTime?.()}>
            <Box display="flex" flexDirection="column">
              <Typography fontWeight={500} color={pink[400]}>
                {dayjs(time).format('HH:mm A')}
              </Typography>
              <Typography fontWeight={500}>{dayjs(time).format('DD/MM/YYYY')}</Typography>
            </Box>
          </EditLine>
          <EditLine icon={<PermIdentityIcon fontSize="large" />} onClickEdit={() => onEditStaff?.()}>
            <Typography fontWeight={500}>{staff ? staff.nickName : t('staff.booking.no-select-yet')}</Typography>
          </EditLine>
        </Box>
        <Box display="flex" justifyContent="center" color={theme.palette.common.white} paddingBottom={2}>
          <Box width={120} display="flex" flexDirection="column" gap={1}>
            <Button label={t('label.confirm')} background={pink[400]} color="inherit" fullWidth onClick={onConfirm} />
            <Button label={t('label.reject')} background={grey[700]} color="inherit" fullWidth onClick={onReject} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const EditLine: React.FC<{ children?: React.ReactNode; icon: React.ReactNode; onClickEdit: () => void }> = ({
  children,
  icon,
  onClickEdit,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width={48}>{icon}</Box>
      <Box width="calc(100% - 60px)" paddingLeft={1}>
        {children}
      </Box>
      <IconButton onClick={onClickEdit}>
        <EditIcon fontSize="large" />
      </IconButton>
    </Box>
  );
};
