import { Box, Grid, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { DEFAULT_LANGUAGE } from '../../../libs/constants';
import { splitArrayIntoChunk } from '../../../libs/utils/splitArrayIntoChunk';

const TextBox = styled(Box)<{ color: string }>(({ theme, color }) => ({
  width: 160,
  height: 80,
  border: `1px solid ${theme.palette.grey[900]}`,
  backgroundColor: color,
  color: theme.palette.grey[900],
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));
export interface IService {
  id: string;
  name: { [key: string]: string };
  onClick?: () => void;
}
interface IFormColumn {
  listItems: IService[];
  color: string;
}
const FormColumn: React.FC<IFormColumn> = ({ listItems, color }) => {
  return (
    <>
      <Grid container flexDirection="column" spacing={0.5}>
        {listItems.map((item) => {
          return (
            <Grid item key={item.id}>
              <TextBox color={color} onClick={item.onClick}>
                {item.name[DEFAULT_LANGUAGE]}
              </TextBox>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export interface IServiceDetail {
  listData: IService[];
  displayOnRow: number;
  color: string;
  name: { [key: string]: string };
}
const ServiceDetail: React.FC<IServiceDetail> = ({ listData, displayOnRow, color, name }) => {
  const remakeData: IService[][] = useMemo(() => {
    return splitArrayIntoChunk(listData, displayOnRow);
  }, [listData]);
  return (
    <Box>
      <Typography fontSize={16} color={(theme) => theme.palette.grey[700]}>
        {name[DEFAULT_LANGUAGE]}
      </Typography>
      <Grid container spacing={0.5}>
        {remakeData.map((item, i) => {
          return (
            <Grid item key={i}>
              <FormColumn listItems={item} color={color} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default React.memo(ServiceDetail);
