import { CurrencyTypes } from '@mtt-nails/consts';
import { Box, Grid, styled, Typography } from '@mui/material';
import React from 'react';

import { DEFAULT_LANGUAGE } from '../../../libs/constants';
import { symbolUnit } from '../../../libs/utils';

const ActionButton = styled(Box)(({ theme }) => ({
  width: 25,
  height: 25,
  borderRadius: '50%',
  border: `1px solid ${theme.palette.grey[100]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.main,
  transition: 'background-color 0.3s ease-out 100ms',
  '&:hover': {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
  },
  cursor: 'pointer',
}));

const Text = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export interface IEditQuantity {
  value: number | string;
  onIncrease: () => void;
  onDecrease: () => void;
}
const EditQuantity: React.FC<IEditQuantity> = ({ value, onIncrease, onDecrease }) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <ActionButton onClick={onIncrease}>+</ActionButton>
      </Grid>
      <Grid item>
        <Typography>{value}</Typography>
      </Grid>
      <Grid item>
        <ActionButton onClick={onDecrease}>-</ActionButton>
      </Grid>
    </Grid>
  );
};

export interface IInvoiceServiceLine {
  id: string;
  serviceName: { [key: string]: string };
  price: { value: string | number; currency: CurrencyTypes };
  editQuantity: IEditQuantity;
}
const InvoiceServiceLine: React.FC<IInvoiceServiceLine> = ({ serviceName, price, editQuantity }) => {
  return (
    <Grid container flexDirection="row" alignItems="center" justifyContent="space-between" spacing={2}>
      <Grid item xs={6}>
        <Text>{serviceName[DEFAULT_LANGUAGE]}</Text>
      </Grid>
      <Grid item xs={2}>
        {price.value}
        {symbolUnit(price.currency)}
      </Grid>
      <Grid item xs={4}>
        <EditQuantity {...editQuantity} />
      </Grid>
    </Grid>
  );
};

export default React.memo(InvoiceServiceLine);
