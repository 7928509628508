import { useLocalObservable } from 'mobx-react-lite';
import React, { useContext } from 'react';

import { IRootStore, RootStore } from '../../store/RootStore';

export const StoreContext = React.createContext<IRootStore | null>(null);

export const StoreProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const store = useLocalObservable(() => new RootStore());
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = (): IRootStore => {
  const store = useContext(StoreContext);
  if (!store) throw new Error('Hook must be call in StoreProvider');

  return store;
};

export const useAuthStore = (): IRootStore['authStore'] => {
  const store = useStore();
  return store['authStore'];
};
export const useAppStore = (): IRootStore['appStore'] => {
  const store = useStore();
  return store['appStore'];
};
export const useShopStore = (): IRootStore['shopStore'] => {
  const store = useStore();
  return store['shopStore'];
};
export const useStaffStore = (): IRootStore['staffStore'] => {
  const store = useStore();
  return store['staffStore'];
};
export const useStorageStore = (): IRootStore['storageStore'] => {
  const store = useStore();
  return store['storageStore'];
};
