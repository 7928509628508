import { EventNames } from '@mtt-nails/consts';
import socket from '@mtt-nails/socket-events';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StaffCheckedIn } from '../../components/templates/checked-in-staff';
import Layout from '../../components/templates/Layout';
import { useAppStore } from '../../providers/StoreProvider';
import { Store } from './store';

export const StaffCheckedInPage = () => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const store = useLocalObservable(() => new Store(appStore));

  const onCheckout = (userId: string) => {
    store.staffCheckout(userId);
  };

  const loadData = () => {
    store.getAvailableStaffs();
  };

  React.useEffect(() => {
    loadData();
    socket.registerListener(EventNames.StaffCheckedIn, loadData);
    socket.registerListener(EventNames.StaffCheckedOut, loadData);
    socket.registerListener(EventNames.ActivityEnded, loadData);
    socket.registerListener(EventNames.ActivityStarted, loadData);
    socket.registerListener(EventNames.ActivityCancelled, loadData);
    socket.registerListener(EventNames.ActivityReady, loadData);
    return () => {
      socket.unregisterListener(EventNames.StaffCheckedIn, loadData);
      socket.unregisterListener(EventNames.StaffCheckedOut, loadData);
      socket.unregisterListener(EventNames.ActivityEnded, loadData);
      socket.unregisterListener(EventNames.ActivityStarted, loadData);
      socket.unregisterListener(EventNames.ActivityCancelled, loadData);
      socket.unregisterListener(EventNames.ActivityReady, loadData);
    };
  }, []);

  return (
    <Layout title={t('menu.title.checked-in-staff')}>
      <Observer>{() => <StaffCheckedIn data={store.displayList} onCheckout={onCheckout} />}</Observer>
    </Layout>
  );
};
