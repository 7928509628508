import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, IconButton, Paper, styled, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { theme } from '../../../libs/themes';
import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { CardBooking } from '../../molecules/card-booking';

export interface ILeftPanelBookingProps {
  bookingStore: BookingStore;
  children?: React.ReactNode;
  modalStore: ModalStore;
}

const WrapBox = styled(Box)(() => ({
  height: 600,
  overflowY: 'hidden',
  '&:hover': {
    overflowY: 'overlay',
  },
}));

const Panel: React.FC<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  bookingStore: BookingStore;
  modalStore: ModalStore;
}> = observer(({ isOpen, onOpen, onClose, bookingStore, modalStore }) => {
  const { t } = useTranslation();

  const onClick = (id: string, cb: () => void) => {
    bookingStore.setSelectedActivity(id);
    cb();
  };

  useEffect(() => {
    const fetch = autorun(() => {
      if (isOpen) bookingStore.convertBookingList();
    });
    return () => {
      fetch();
    };
  }, [isOpen]);

  return isOpen ? (
    <Paper
      sx={{
        top: '0',
        left: '0',
        width: '332px',
        background: pink[200],
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          paddingY: '16px',
          paddingX: '12px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          background: pink[200],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box width="50%">
            <Button
              fullWidth
              color="inherit"
              sx={{
                background: theme.palette.common.white,
                fontWeight: 600,
              }}
            >
              {t('label.list-by-date')}
            </Button>
          </Box>
          <Box>
            <IconButton onClick={onClose}>
              <KeyboardArrowLeftIcon sx={{ color: pink[400] }} />
            </IconButton>
          </Box>
        </Box>

        {bookingStore.listBooking.length === 0 && <Typography color="white">{t('label.no-booking-today')}</Typography>}
        {bookingStore.listBooking.length > 0 && (
          <WrapBox>
            {bookingStore.listBooking.map((booking, idx) => (
              <CardBooking
                key={idx}
                {...booking}
                lastItem={idx === bookingStore.listBooking.length - 1}
                onEditStaff={() => onClick(booking.id, modalStore.toggleStaffModal)}
                onEditTime={() => onClick(booking.id, modalStore.toggleTimeModal)}
                onReject={() => onClick(booking.id, modalStore.toggleMessageModal)}
                onConfirm={() => bookingStore.confirmPendingBooking(booking.id)}
              />
            ))}
          </WrapBox>
        )}
      </Box>
    </Paper>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '50px',
        width: '50px',
        background: pink[200],
        borderRadius: '0 5px 5px 0',
      }}
    >
      <IconButton onClick={onOpen}>
        <KeyboardArrowRightIcon sx={{ color: pink[400] }} />
      </IconButton>
    </Box>
  );
});

export const LeftPanelBooking: React.FC<ILeftPanelBookingProps> = observer(({ bookingStore, children, modalStore }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '100%',
      }}
    >
      <Panel isOpen={isOpen} onOpen={onOpen} onClose={onClose} bookingStore={bookingStore} modalStore={modalStore} />
      <Box
        sx={{
          maxWidth: isOpen ? 'calc(100% - 332px)' : '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
});
