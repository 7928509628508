import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel as StepLabelMui,
  Stepper as StepperMui,
  styled,
} from '@mui/material';
import React from 'react';

const StepLabel = styled(StepLabelMui)(({ theme }) => ({
  flexDirection: 'column',
  padding: 0,
  '& .MuiStepLabel-labelContainer': {
    width: 'auto',
    '& .MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-active': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiStepLabel-iconContainer': {
    paddingRight: 0,
  },
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      opacity: 0.5,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      opacity: 0.5,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.main,
    opacity: 0.5,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  '& .QontoStepIcon-completedIcon': {
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .QontoStepIcon-circle': {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: 'rgb(229, 79, 124, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active || completed ? (
        <div className="QontoStepIcon-completedIcon">{icon}</div>
      ) : (
        <div className="QontoStepIcon-circle">{icon}</div>
      )}
    </QontoStepIconRoot>
  );
}

export interface IStepItem {
  title: string;
  component: React.ReactNode;
}
export interface IStepper {
  listSteps: IStepItem[];
  activeStep: number;
  onHandleStep: (step: number) => void;
}
const Stepper: React.FC<IStepper> = ({ listSteps, activeStep, onHandleStep }) => {
  return (
    <>
      <StepperMui nonLinear activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {listSteps.map((item, index) => {
          return (
            <Step key={index} sx={{ cursor: 'pointer' }}>
              <span
                onClick={() => {
                  onHandleStep(index);
                }}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>{item.title}</StepLabel>
              </span>
            </Step>
          );
        })}
      </StepperMui>
      <Box width="100%" mt={5}>
        {listSteps[activeStep < listSteps.length - 1 ? activeStep : listSteps.length - 1].component}
      </Box>
    </>
  );
};

export default React.memo(Stepper);
