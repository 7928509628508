import { Grid } from '@mui/material';
import React from 'react';

import ServiceDetail, { IServiceDetail } from '../../molecules/service-detail';

export interface IListServiceDetail {
  listServiceDetails: IServiceDetail[];
}
const ListServiceDetails: React.FC<IListServiceDetail> = ({ listServiceDetails }) => {
  return (
    <Grid container spacing={2}>
      {listServiceDetails.map((item, i) => {
        return (
          <Grid item key={i}>
            <ServiceDetail {...item} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default React.memo(ListServiceDetails);
