import { Box, Dialog, Grid, styled, TextField, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { Button } from '../../atom';

interface IProps {
  store: ModalStore;
  bookingStore: BookingStore;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  textAlign: 'center',
  backgroundColor: pink[300],
  color: theme.palette.primary.contrastText,
}));

export const DeleteBookingModal: React.FC<IProps> = observer(({ store, bookingStore }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = autorun(() => {
      if (!isEmpty(bookingStore.selectedActivity)) {
        runInAction(() => {
          store.customer?.findCustomerById(bookingStore.selectedActivity.customerCode);
          store.updateSelectedServices(bookingStore.selectedActivity.serviceCategory);
        });
      }
    });
    return () => {
      fetch();
    };
  }, []);

  const onSubmit = () => {
    bookingStore.cancelConfirmedBooking(bookingStore.selectedActivity.id, store.comment);
  };

  return (
    <Dialog onClose={store.toggleDeleteModal} open={store.deleteModal} fullWidth maxWidth="md">
      <Box>
        <WrapHeader>{t('dialog.new-booking.delete-booking')}</WrapHeader>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.date')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {dayjs(get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.time')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {dayjs(get(bookingStore.selectedActivity, 'trackedTimes.expectedTime', null)).format('HH:mm')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.customer-code')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{get(bookingStore.selectedActivity, 'customerCode', '')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.table')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{get(bookingStore.selectedActivity, 'staff.table', '')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.name')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{store.customer?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder={t('dialog.new-booking.placeholder')}
              multiline
              rows={4}
              fullWidth
              value={store.comment}
              onChange={(e) => store.handleChangeComment(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.confirm')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.cancel')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={store.toggleDeleteModal}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
});
