import { Operator } from '@mtt-nails/apis/dist/v3';
import { Box, Collapse } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { moneyFormat } from '../../../libs/utils/formatMoney';
import { Income } from '../../../pages/daily-income-page/store';

dayjs.extend(localizedFormat);
interface IIncomeItem {
  userId: string;
  name: string;
  customersAmount: string;
  income: Income;
}

interface IRowTableIncomeItem extends IIncomeItem {
  isOpenCollapse: boolean;
  handleClickCollapse: (value: string) => void;
  listCustomer: Operator.Statistic.CustomerActivity[];
}

interface ITableIncomeDaily {
  data: IIncomeItem[];
  selectedStaff: string;
  handleClickCollapse: (value: string) => void;
  listCustomer: any[];
}

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const StyledTableCellSubHeader = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
  },
}));

const StyledTableCellFooter = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

const Row: React.FC<IRowTableIncomeItem> = ({
  userId,
  name,
  listCustomer,
  income,
  customersAmount,
  isOpenCollapse,
  handleClickCollapse,
}) => {
  const { t } = useTranslation();

  const TITLE_SUB_COLUMN = [
    t('tbl.income-daily.id-customer'),
    t('tbl.income-daily.check-in-time'),
    t('tbl.income-daily.start-time'),
    t('tbl.income-daily.finish-time'),
    t('tbl.income-daily.paid-amount'),
  ];
  return (
    <>
      <TableRow onClick={() => handleClickCollapse(userId)} sx={{ cursor: 'pointer' }}>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell>{customersAmount}</TableCell>
        <TableCell>{moneyFormat(income.value)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Table size="small" aria-label="history-detail">
                <TableHead>
                  <TableRow>
                    {TITLE_SUB_COLUMN.map((column, index) => (
                      <StyledTableCellSubHeader key={index}>{column}</StyledTableCellSubHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listCustomer.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.customerCode}</TableCell>
                        <TableCell>{dayjs(item.checkedTime).format('LTS')}</TableCell>
                        <TableCell>{dayjs(item.startedTime).format('LTS')}</TableCell>
                        <TableCell>{dayjs(item.finishedTime).format('LTS')}</TableCell>
                        <TableCell>{moneyFormat(item.amount.value)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TableIncomeDaily: React.FC<ITableIncomeDaily> = ({ data, selectedStaff, handleClickCollapse, listCustomer }) => {
  const { t } = useTranslation();
  const columns = [
    t('tbl.income-daily.name-worker'),
    t('tbl.income-daily.number-of-customer'),
    t('tbl.income-daily.income-daily'),
  ];

  const dataFooter = React.useMemo(() => {
    let totalCustomer = 0;
    let totalMoney = 0;
    data.forEach((item) => {
      totalCustomer += +item.customersAmount;
      totalMoney += +item.income.value;
    });
    return [t('tbl.income-daily.sum'), totalCustomer, totalMoney];
  }, [data]);

  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCellHeader key={index}>{column}</StyledTableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <Row
                  key={index}
                  {...row}
                  isOpenCollapse={selectedStaff === row.userId}
                  handleClickCollapse={handleClickCollapse}
                  listCustomer={listCustomer}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow key={data.length}>
              {dataFooter.map((val, i) => {
                return (
                  <StyledTableCellFooter key={i}>
                    {i === dataFooter.length - 1 ? moneyFormat(val) : val}
                  </StyledTableCellFooter>
                );
              })}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableIncomeDaily;
