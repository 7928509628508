import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableIncomeDaily from '../../molecules/table-income-daily';
import { IDailyIncomeContainerProps } from './types';

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.5rem',
}));

const DailyIncomeContainer: React.FC<IDailyIncomeContainerProps> = observer(({ store }) => {
  const { t } = useTranslation();
  const handleClickCollapse = (value: string) => {
    store.setSelectedStaff(value);
    store.getCustomerServed();
  };

  return (
    <>
      <Box paddingY={2}>
        <PageTitle>{t('page.daily-income.title')}</PageTitle>
      </Box>
      <TableIncomeDaily
        data={store.staffs}
        selectedStaff={store.selectedStaff}
        handleClickCollapse={handleClickCollapse}
        listCustomer={store.customers}
      />
    </>
  );
});

export default DailyIncomeContainer;
