import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#e54f7c',
    },
    secondary: {
      main: '#2A72CA',
    },
    grey: {
      '100': 'lightgray',
      '500': '#9e9e9e',
      '900': '#212121',
    },
  },
  spacing: 8,
});
