import { operatorApi } from '@mtt-nails/apis/dist/v3';
import { StaffActivitiesStatus } from '@mtt-nails/consts';
import { makeAutoObservable, runInAction } from 'mobx';

import { IStaffCheckedInItemProps } from '../../components/molecules';
import { convertCheckedInStaff } from '../../libs/utils/convertCheckedInStaff';
import { AppStore } from '../../store/domains/AppStore';

export class Store {
  listAvailableStaffs: any[] = [];
  loadingData = false;

  constructor(private appStore: AppStore) {
    makeAutoObservable(this);
  }

  get displayList() {
    const waitingOnes = this.listAvailableStaffs
      .slice()
      .filter((staff: IStaffCheckedInItemProps) => staff.status === StaffActivitiesStatus.Waiting)
      .sort((staff1: IStaffCheckedInItemProps, staff2: IStaffCheckedInItemProps) => {
        return (staff1?.position || 0) - (staff2?.position || 0);
      });
    const noneWaitingOnes = this.listAvailableStaffs
      .slice()
      .filter((staff: IStaffCheckedInItemProps) => staff.status !== StaffActivitiesStatus.Waiting);
    return waitingOnes.concat(noneWaitingOnes);
  }

  getAvailableStaffs = async () => {
    try {
      runInAction(() => (this.loadingData = true));
      const { data } = await operatorApi.getPositionRelatedListStaff();
      runInAction(() => {
        this.listAvailableStaffs = convertCheckedInStaff(data as any);
      });
    } catch (err) {
      this.appStore.notification?.setError(err);
    } finally {
      runInAction(() => (this.loadingData = false));
    }
  };

  staffCheckout = async (staffId: string) => {
    try {
      await operatorApi.checkoutForStaff(staffId);
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };
}
