import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ItemServingCustomer } from '../../organisms/item-serving-customer';
import { IServingListCustomerProps } from './types';

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.5rem',
}));

const ServingListCustomer: React.FC<IServingListCustomerProps> = observer(
  ({ customers, handleChangeCustomerCode, handleClickFinish }) => {
    const { t } = useTranslation();

    return (
      <>
        <Box paddingY={2}>
          <PageTitle>{t('page.serving-customer.title')}</PageTitle>
        </Box>
        {customers.map(({ staff, trackedTimes, services, ...customer }) => (
          <Box key={customer.id} paddingY={1.25}>
            <ItemServingCustomer
              {...customer}
              services={services}
              bookNumber={trackedTimes.expectedTime}
              currentTable={staff.table}
              remainTime={trackedTimes.timeRemaining}
              onChangeCustomerCode={handleChangeCustomerCode}
              onClickFinish={handleClickFinish}
            />
          </Box>
        ))}
      </>
    );
  },
);

export default ServingListCustomer;
