import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ItemCustomerCheckin } from '../../organisms';
import { IListActivitiesProps } from './types';

const ListActivities: React.FC<IListActivitiesProps> = observer(
  ({ shopStore, onClickChangeTable, onClickDeleleItem, onClickStartService }) => {
    return (
      <>
        {shopStore.checkinItems.map(({ staff, trackedTimes, ...customer }, index) => (
          <Box key={index} paddingY={1.25}>
            <ItemCustomerCheckin
              {...customer}
              bookNumber={trackedTimes.expectedTime}
              currentTable={staff.table}
              onClickChangeTable={onClickChangeTable}
              onClickDeleleItem={onClickDeleleItem}
              onClickStartService={onClickStartService}
            />
          </Box>
        ))}
        {shopStore.readyItems.map(({ staff, trackedTimes, ...customer }, index) => (
          <Box key={index} paddingY={1.25}>
            <ItemCustomerCheckin
              {...customer}
              bookNumber={trackedTimes.expectedTime}
              isAvailable
              currentTable={staff.table}
              onClickChangeTable={onClickChangeTable}
              onClickDeleleItem={onClickDeleleItem}
              onClickStartService={onClickStartService}
            />
          </Box>
        ))}
      </>
    );
  },
);

export default ListActivities;
