import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { Box, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atom';

export interface IPromotionItemProps {
  id?: string;
  label?: string;
  description?: string;
  onPromotionClick?: () => void;
}

const WrapPromotion = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: theme.spacing(1, 2),
  borderRadius: '16px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
}));

const WrapPromotionContent = styled(Box)(() => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const WrapPromotionIcon = styled(Box)(({ theme }) => ({
  width: '64px',
  height: '64px',
  borderRadius: '50%',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  margin: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const WrapPromotionDescription = styled(Box)(({ theme }) => ({
  width: '85%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginLeft: theme.spacing(2),
}));

export const PromoItem: React.FC<IPromotionItemProps> = ({ label, description, onPromotionClick }) => {
  const { t } = useTranslation();

  return (
    <WrapPromotion>
      <WrapPromotionContent>
        <WrapPromotionIcon>
          <ConfirmationNumberIcon color="primary" fontSize="large" />
        </WrapPromotionIcon>
        <WrapPromotionDescription>
          <Typography variant="h5" color="primary">
            {label}
          </Typography>
          <Typography variant="subtitle1">{description}</Typography>
        </WrapPromotionDescription>
      </WrapPromotionContent>
      <Button
        label={t('payment.promotion.use-now')}
        color="primary"
        onClick={onPromotionClick}
        type="button"
        variant="contained"
      />
    </WrapPromotion>
  );
};
