import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';

export interface IValues {
  label: string;
  value: string;
}

export interface ISelectsProps {
  label: string;
  defaultValue?: IValues;
  values: IValues[];
  errorMessage?: string;
  onChange?: (value?: string) => void;
}

export const Selects: React.FC<ISelectsProps> = ({ label, defaultValue, values, errorMessage, onChange }) => {
  const [value, setValue] = React.useState(defaultValue?.value || '');

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    if (onChange) onChange(event.target.value);
  };
  return (
    <FormControl fullWidth error={!!errorMessage}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} label={label} onChange={handleChange}>
        {values?.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};
