import { Observer } from 'mobx-react-lite';
import React from 'react';

import CustomerCheckinModel from '../../components/organisms/customer-checkin-model';
import { useShopStore } from '../../providers/StoreProvider';
import { Store } from './store';

interface ICheckinModel {
  onCancel: () => void;
  store: Store;
}
const CheckinModel: React.FC<ICheckinModel> = ({ onCancel, store }) => {
  const shopStore = useShopStore();
  return (
    <Observer>
      {() => (
        <CustomerCheckinModel
          shopStore={store}
          onCancel={onCancel}
          enableSelectService={shopStore.config?.enableSelectService}
          enableSelectStaff={shopStore.config?.enableSelectStaff}
          onSearchCustomer={store.customer?.findCustomerById}
        />
      )}
    </Observer>
  );
};

export default CheckinModel;
