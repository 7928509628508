import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useDevice } from '../../../libs/hooks/useDevice';
import { translate } from '../../../libs/utils/translate';
import { Input } from '../../atom';

interface IFormFullName {
  firstName?: string;
  lastName?: string;
  onHandleChangeFirstName: (v: string) => void;
  onHandleChangeLastName: (v: string) => void;
}

interface IInputFullName {
  firstName: string;
  lastName: string;
}

const schema = yup.object().shape({
  firstName: yup.string().required(
    translate('validations.required', {
      field: translate('fields.firstName'),
    }),
  ),
  lastName: yup.string().required(
    translate('validations.required', {
      field: translate('fields.lastName'),
    }),
  ),
});

const InputFullName: React.FC<IFormFullName> = ({
  firstName,
  lastName,
  onHandleChangeFirstName,
  onHandleChangeLastName,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const defaultValues: IInputFullName = {
    firstName: firstName || '',
    lastName: lastName || '',
  };

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm<IInputFullName>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const firstName_ = React.useMemo(
    () =>
      register('firstName', {
        onChange: (e: any) => {
          onHandleChangeFirstName(e.target.value);
        },
      }),
    [register],
  );
  const lastName_ = React.useMemo(
    () =>
      register('lastName', {
        onChange: (e: any) => {
          onHandleChangeLastName(e.target.value);
        },
      }),
    [register],
  );

  React.useEffect(() => {
    trigger();
  }, []);

  return (
    <form>
      <Box sx={{ display: isMobile ? 'block' : 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ width: isMobile ? '100%' : '40%' }}>
          <Input
            fullWidth
            placeholder={t('fields.firstName')}
            {...firstName_}
            errorMessage={errors.firstName?.message}
            variant="standard"
          />
        </Box>
        <Box sx={{ width: isMobile ? '100%' : '40%' }}>
          <Input
            variant="standard"
            fullWidth
            placeholder={t('fields.lastName')}
            {...lastName_}
            errorMessage={errors.lastName?.message}
          />
        </Box>
      </Box>
    </form>
  );
};

export default InputFullName;
