import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckinForm } from '../../components/organisms/checkin-form';
import Layout from '../../components/templates/Layout';
import { useShopStore, useStaffStore } from '../../providers/StoreProvider';
import { Store } from './store';

export const StaffCheckInPage: React.FC = observer(() => {
  const { t } = useTranslation();
  const staffStore = useStaffStore();
  const shopStore = useShopStore();
  const store = useLocalObservable(() => new Store(staffStore));

  return (
    <Layout title={t('menu.title.staff-checkin')}>
      <Observer>
        {() => (
          <CheckinForm
            username={store.staff.username}
            password={store.staff.password}
            onUsernameChange={store.staff.setUsername}
            onPasswordChange={store.staff.setPassword}
            onCheckin={store.checkIn}
            shopId={shopStore.shopId}
          />
        )}
      </Observer>
    </Layout>
  );
});
