import dayjs from 'dayjs';

import { formatDate } from '../../pages/booking-page/store';

const NUMBER_CHECKIN_FORMAT = 'HH:mm:ss';

export const timeConverter = {
  formatNumber: (timeString: Date) => dayjs(timeString).format(NUMBER_CHECKIN_FORMAT),
};

export const getTimeRange30m = (startTime: dayjs.Dayjs, endTime: dayjs.Dayjs, interval: number, selected = dayjs()) => {
  const blocks = [];
  const minTemp = startTime.minute();
  const time = minTemp > interval ? startTime.add(60 - minTemp, 'minute') : startTime.add(minTemp, 'minute');
  let temp = 0;
  while (time.add(temp, 'minute').isBefore(endTime)) {
    const strStart = time.add(temp, 'minute').format('HH:mm');
    const strEnd = time.add(interval + temp, 'minute').format('HH:mm');
    temp += interval;
    if (formatDate(selected.valueOf(), strStart).isBefore(dayjs())) continue;
    blocks.push({ from: strStart, to: strEnd, available: false });
  }
  return blocks;
};
