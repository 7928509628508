export * from './booking-page';
export * from './daily-income-page';
export * from './home';
export * from './login';
export * from './not-found';
export * from './payment';
export * from './register-customer-page';
export * from './serving-customers';
export * from './setting';
export * from './staff-check-in-page';
export * from './staff-checked-in-page';
