import { CurrencyTypes } from '@mtt-nails/consts';

export const symbolUnit = (unit: CurrencyTypes) => {
  switch (unit) {
    case CurrencyTypes.Percentage:
      return '%';
    case CurrencyTypes.Dollar:
      return '$';
    case CurrencyTypes.Euro:
      return '€';
    default:
      return '';
  }
};
