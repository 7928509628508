import { Grid } from '@mui/material';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';

import { DEFAULT_LANGUAGE } from '../../../libs/constants';
import { ServiceCard } from '../service-card';

interface IListServicesCard {
  services: any[];
}
const ListServicesCard: React.FC<IListServicesCard> = observer(({ services }) => {
  return (
    <>
      <Grid container spacing={2}>
        {services.map(({ id, name, image, toggleSelected, isSelected }) => {
          return (
            <Grid item key={id}>
              <Observer>
                {() => (
                  <ServiceCard
                    label={name[DEFAULT_LANGUAGE]}
                    image={image.thumbnail}
                    onClick={toggleSelected}
                    checked={isSelected}
                  />
                )}
              </Observer>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
});

export default ListServicesCard;
