import { Box, Grid, styled, Typography as TypographyMui } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TWaitingListCustomer } from '../../../pages/home/types';
import { Button, Input } from '../../atom';
import ItemTableForm from './ItemTableForm';

const Wrapper = styled(Box)({
  width: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 36,
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, rgb(229, 79, 124, 0.5))`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.grey[50],
}));

const Typography = styled(TypographyMui)(() => ({
  fontSize: 22,
}));

interface IDeleteActivityForm {
  activity: TWaitingListCustomer;
  handleConfirmDeleteActivity: (activityId: string, comment: string) => void;
  onCancel: () => void;
}

const DeleteActivityForm: React.FC<IDeleteActivityForm> = observer(
  ({ handleConfirmDeleteActivity, activity, onCancel }) => {
    const [comment, setComment] = useState('');
    const { t } = useTranslation();

    return (
      <Wrapper>
        <Header>{t('component.organisms.delete-activity-form.header')}</Header>
        <Box p={2}>
          <Grid container spacing={1} flexDirection="column">
            <Box mt={10} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.delete-activity-form.title.time')}>
                    <Typography>
                      {typeof activity?.bookNumber === 'string'
                        ? new Date(activity?.bookNumber).toISOString().substring(11, 19)
                        : activity?.bookNumber.toISOString().substring(11, 19)}
                    </Typography>
                  </ItemTableForm>
                </Grid>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.delete-activity-form.title.phone')}>
                    <Typography></Typography>
                  </ItemTableForm>
                </Grid>
              </Grid>
            </Box>
            <Box mt={10} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.delete-activity-form.title.name')}>
                    <Typography>{activity?.customerName}</Typography>
                  </ItemTableForm>
                </Grid>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.delete-activity-form.title.table')}>
                    <Typography>{activity?.customerCode}</Typography>
                  </ItemTableForm>
                </Grid>
              </Grid>
            </Box>
            <Box mt={10} width="100%">
              <Input
                fullWidth
                multiline
                maxRows={10}
                minRows={4}
                placeholder={t('component.organisms.change-table-form.title.comment')}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
            <Box mt={10} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('component.organisms.change-table-form.button.confirm')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => handleConfirmDeleteActivity(activity.activityId, comment)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('component.organisms.change-table-form.button.close')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={onCancel}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Wrapper>
    );
  },
);

export default DeleteActivityForm;
