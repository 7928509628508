import { CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import Snackbar from './components/atom/snackbar';
import i18n from './languages/i18n';
import { theme } from './libs/themes';
import { StoreProvider } from './providers/StoreProvider';
import { AppRouter } from './routers';
import { StorageStore } from './store/domains/StorageStore';

function App() {
  const storageStore = new StorageStore();
  useEffect(() => {
    const lang = storageStore.getDefaultLanguage();
    if (lang) i18n.changeLanguage(lang);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <StoreProvider>
          <I18nextProvider i18n={i18n}>
            <AppRouter />
            <Snackbar />
          </I18nextProvider>
        </StoreProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
