import { Box, Dialog, Grid, styled, TextField, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { registerCustomer } from '../../../routers/paths';
import { Button } from '../../atom';
import ListServicesCard from '../../molecules/ListServicesCard';

interface IProps {
  store: ModalStore;
  onSearchCustomer?: (value: string) => void;
  onCallback: () => void;
  bookingStore: BookingStore;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  textAlign: 'center',
  backgroundColor: pink[300],
  color: theme.palette.primary.contrastText,
}));

const WrapService = styled(Box)(({ theme }) => ({
  minHeight: 46,
  padding: 10,
  border: `1px solid ${theme.palette.grey[100]}`,
}));

export const CreateBookingModal: React.FC<IProps> = observer(
  ({ store, onSearchCustomer, onCallback, bookingStore }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleFindCustomerById = (e: any) => {
      onSearchCustomer?.(e.target.value);
    };

    const onSubmit = () => {
      store.booking(
        bookingStore.selectedDate,
        bookingStore.selectedInterval.from,
        onCallback,
        bookingStore.selectedInterval.staff?.table,
      );
    };

    return (
      <Dialog onClose={store.toggleCreateModal} open={store.createModal} fullWidth maxWidth="md">
        <Box>
          <WrapHeader>{t('dialog.new-booking.title')}</WrapHeader>
          {!store.customer?.showMissingCustomerCodeWarning ? (
            <Grid container spacing={3} padding={2}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>{t('dialog.new-booking.date')}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{dayjs(bookingStore.selectedDate).format('DD/MM/YYYY')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>{t('dialog.new-booking.time')}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{bookingStore.selectedInterval.from}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>{t('dialog.new-booking.customer-code')}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField variant="standard" fullWidth inputProps={{ onBlur: handleFindCustomerById }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>{t('dialog.new-booking.name')}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField variant="standard" fullWidth disabled value={store.customer?.name || ''} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WrapService>
                  <Typography>{t('dialog.new-booking.services')}</Typography>
                  <ListServicesCard services={store.services} />
                </WrapService>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} width="100%">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        label={t('dialog.new-booking.confirm')}
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={onSubmit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        label={t('dialog.new-booking.cancel')}
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={store.toggleCreateModal}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3} padding={4}>
              <Grid item xs={12}>
                <Typography textAlign="center">{t('dialog.new-booking.error-customer')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Box mr={1}>
                    <Button
                      label={t('dialog.new-booking.create-customer-code')}
                      color="primary"
                      variant="outlined"
                      onClick={() => navigate(registerCustomer)}
                    />
                  </Box>
                  <Box ml={1}>
                    <Button
                      label={t('dialog.new-booking.re-enter-customer-code')}
                      color="primary"
                      variant="outlined"
                      onClick={store.customer.reset}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Dialog>
    );
  },
);
