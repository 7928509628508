import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atom';
import { BookingNumber } from '../../molecules/booking-number';

interface IItemCustomerCheckinProps {
  id: string;
  customerCode: string;
  customerName?: string;
  bookNumber: Date | string;
  currentTable: number;
  isAvailable?: boolean;
  onClickStartService: (id: string) => void;
  onClickChangeTable: (
    activityid: string,
    bookNumber: string | Date,
    customerCode: string,
    currentTable: number,
    customerName: string,
  ) => void;
  onClickDeleleItem: (
    activityId: string,
    bookNumber: string | Date,
    customerCode: string,
    currentTable: number,
    customerName: string,
  ) => void;
}

const ContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default}`,
  position: 'relative',
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${theme.palette.background.default}`,
  borderRadius: 50,
  width: 24,
  height: 24,
  position: 'absolute',
  top: '-12px',
  right: '-12px',
  cursor: 'pointer',
}));

const CustomCloseIcon = styled(CloseIcon)(() => ({
  width: '12px',
  height: '12px',
}));

export const ItemCustomerCheckin: React.FC<IItemCustomerCheckinProps> = ({
  id,
  customerCode,
  customerName = '',
  bookNumber,
  currentTable,
  isAvailable = false,
  onClickStartService,
  onClickChangeTable,
  onClickDeleleItem,
}) => {
  const { t } = useTranslation();
  return (
    <ContentContainer p={1} display="flex" borderRadius={1} width="100%" justifyContent="space-between">
      <Box width="30%">
        <BookingNumber time={bookNumber} />
      </Box>
      <Box width="20%">
        <Box paddingY={0.5} marginY="1px">
          {customerName}
        </Box>
        <Box paddingY={0.5} marginY="1px">
          {customerCode}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <CalendarMonthIcon />
        <Typography paddingLeft={1} paddingTop={0.25}>
          {currentTable}
        </Typography>
      </Box>
      <Box width="20%" alignSelf="center">
        <Button
          label={t('components.organisms.item-customer-checkin.start-button')}
          fullWidth
          color="primary"
          onClick={() => onClickStartService(id)}
          disabled={!isAvailable}
          variant="contained"
        />
      </Box>
      <Box width="20%" alignSelf="center">
        <Button
          label={t('components.organisms.item-customer-checkin.change-table-button')}
          fullWidth
          color="secondary"
          onClick={() => onClickChangeTable(id, bookNumber, customerCode, currentTable, customerName)}
          variant="contained"
        />
      </Box>
      <IconContainer>
        <CustomCloseIcon onClick={() => onClickDeleleItem(id, bookNumber, customerCode, currentTable, customerName)} />
      </IconContainer>
    </ContentContainer>
  );
};
