import { StaffActivitiesStatus } from '@mtt-nails/consts';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IOrderIndicatorProps {
  position?: number;
  status?: string;
}

const OrderIndicatorTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

export const OrderIndicator: React.FC<IOrderIndicatorProps> = ({ position, status }) => {
  const { t } = useTranslation();
  switch (status) {
    case StaffActivitiesStatus.Waiting:
      return (
        <OrderIndicatorTypo>
          {position === 0
            ? t('staff.in.turn')
            : t('staff.in.turn.man', {
                position: position,
              })}
        </OrderIndicatorTypo>
      );
    case StaffActivitiesStatus.Ready:
      return <OrderIndicatorTypo>{t('staff.waiting.for.customer')}</OrderIndicatorTypo>;
    case StaffActivitiesStatus.Doing:
      return <OrderIndicatorTypo>{t('staff.working')}</OrderIndicatorTypo>;
    default:
      return null;
  }
};
