import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IStaffCheckedInItemProps, StaffCheckedInItem } from '../../molecules/staff-checked-in-item';

export interface IStaffCheckedInProps {
  data: IStaffCheckedInItemProps[];
  onCheckout: (id: string) => void;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.5, 2),
  color: theme.palette.primary.contrastText,
  background: theme.palette.primary.main,
  borderRadius: theme.spacing(0.625),
}));

const WrapCenterBox = styled(Box)(() => ({
  textAlign: 'center',
}));

const WrapTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2.5, 0),
  color: theme.palette.grey[600],
  fontSize: 20,
}));

export const StaffCheckedIn: React.FC<IStaffCheckedInProps> = ({ data, onCheckout }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <WrapTitle>{t('staff.already.checked.in')}</WrapTitle>
      {data.length > 0 && (
        <WrapHeader>
          <Box width="25%">{t('staff.checked.in.name')}</Box>
          <WrapCenterBox width="15%">{t('staff.checked.in.point')}</WrapCenterBox>
          <WrapCenterBox width="30%">{t('staff.checked.in.order')}</WrapCenterBox>
          <WrapCenterBox width="30%">{t('staff.checked.in.time-check')}</WrapCenterBox>
        </WrapHeader>
      )}
      {data.map((item, idx) => (
        <Observer key={idx}>
          {() => (
            <StaffCheckedInItem
              {...item}
              onCheckout={() => {
                onCheckout(item?.userId || '');
              }}
            />
          )}
        </Observer>
      ))}
    </Box>
  );
};
