import { Observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/templates/Layout';
import { RegisterCustomer } from '../../components/templates/register-customer';
import { useAppStore, useShopStore } from '../../providers/StoreProvider';
import { Store } from './store';

export const RegisterCustomerPage = () => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const shopStore = useShopStore();
  const store = useLocalObservable(() => new Store(appStore, shopStore));

  const onHandleAlert = (err: any) => {
    appStore.notification?.setError(err);
  };

  return (
    <Layout title={t('menu.title.register-customer')}>
      <Observer>
        {() => (
          <RegisterCustomer
            onHandleRegister={store.submit}
            onHandleAlert={onHandleAlert}
            customerCode={store.user.getCustomerCode}
          />
        )}
      </Observer>
    </Layout>
  );
};
