import { Box, Dialog, Grid, MenuItem, styled, TextField, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { Button } from '../../atom';

interface IProps {
  store: ModalStore;
  bookingStore: BookingStore;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  textAlign: 'center',
  backgroundColor: pink[300],
  color: theme.palette.primary.contrastText,
}));

export const StaffModal: React.FC<IProps> = observer(({ store, bookingStore }) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={store.toggleStaffModal} open={store.staffModal} fullWidth maxWidth="md">
      <Box>
        <WrapHeader>{t('dialog.booking-screen.staff-booking')}</WrapHeader>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{t('dialog.new-booking.table')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  select
                  value={store.bookingTable || get(bookingStore.selectedActivity, 'staff.table', '')}
                  fullWidth
                  onChange={(e) => store.onChangeTable(+e.target.value)}
                >
                  {bookingStore.staffCols.map((option, idx) => (
                    <MenuItem key={idx} value={option.staff.table}>
                      {option.staff.nickName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.confirm')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      bookingStore.onConfirmEditStaff(
                        store.bookingTable || get(bookingStore.selectedActivity, 'staff.table', ''),
                        store.toggleStaffModal,
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('dialog.new-booking.cancel')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={store.toggleStaffModal}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
});
