import { useMediaQuery, useTheme } from '@mui/material';

interface IUseDevice {
  isMobile: boolean;
  isDesktop: boolean;
}

export const useDevice = (): IUseDevice => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return {
    isMobile,
    isDesktop,
  };
};
