import { Operator, operatorApi } from '@mtt-nails/apis/dist/v3';
import { ActivitiesStatus } from '@mtt-nails/consts';
import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';

import { transformActivities } from '../../libs/utils/activities';
import { transformService } from '../../libs/utils/services';
import { AppStore } from '../../store/domains/AppStore';
import { Customer } from '../../store/domains/CustomerStore';
import { ShopStore } from '../../store/domains/ShopStore';

const orderByExpectedTime = (ele1: Activity, ele2: Activity) => {
  return dayjs(ele1.trackedTimes.expectedTime).diff(dayjs(ele2.trackedTimes.expectedTime)) > 0 ? 1 : -1;
};

export interface IImageCategory {
  largeImage?: string;
  mediumImage?: string;
  thumbnail: string;
}

export interface ISubCategory {
  id: string;
  name: { [key: string]: string };
  image: IImageCategory;
}

export class SubCategory {
  name: { [key: string]: string };
  id: string;
  image: IImageCategory;
  isSelected = false;

  constructor(category: ISubCategory) {
    makeAutoObservable(this);
    this.name = category.name;
    this.id = category.id;
    this.image = category.image;
  }

  toggleSelected = () => {
    this.isSelected = !this.isSelected;
  };
}

export interface IActivity extends Operator.Activity {
  customerName?: string;
}

export class Activity {
  id: Operator.Activity['id'];
  shopId: Operator.Activity['shopId'];
  customerCode: Operator.Activity['customerCode'];
  status: Operator.Activity['status'];
  services: Operator.Activity['services'];
  serviceCategory: Operator.Activity['serviceCategory'];
  staff: Operator.Activity['staff'];
  trackedTimes: Operator.Activity['trackedTimes'];
  customerName?: string;

  constructor(activity: IActivity) {
    makeAutoObservable(this);
    this.id = activity.id;
    this.shopId = activity.shopId;
    this.customerCode = activity.customerCode;
    this.status = activity.status;
    this.services = activity.services;
    this.serviceCategory = activity.serviceCategory;
    this.staff = activity.staff;
    this.trackedTimes = activity.trackedTimes;
    this.customerName = activity.customerName;
  }
}

export interface ITable {
  id: string;
  name: string;
  table: number;
}

export class Store {
  shopId: string;
  services: SubCategory[];
  staffs: Operator.Staff.Short[];
  customer: Customer | null;
  waitingCustomerList: Activity[];
  customerInfoList: Operator.Customer.Short[];
  table?: ITable;
  tableAvailables?: ITable[];
  isFormCheckinShown: boolean;
  isFormChangeTableShown: boolean;
  isFormDeleteActivityShown: boolean;

  constructor(private appStore: AppStore, shopStore: ShopStore) {
    makeAutoObservable(this);
    this.services = [];
    this.staffs = [];
    this.shopId = shopStore.shopId;
    this.waitingCustomerList = [];
    this.customerInfoList = [];
    this.isFormChangeTableShown = false;
    this.isFormDeleteActivityShown = false;
    this.isFormCheckinShown = false;
    this.customer = new Customer(appStore, shopStore.shopId);
    this.fetchService();
    this.fetchStaffs();
    this.fetchCustomers();
    this.fetchAvailableTable();
    this.fetchActivities();
  }

  get readyItems() {
    return this.waitingCustomerList
      .filter((item: Activity) => item.status === ActivitiesStatus.Ready)
      .sort(orderByExpectedTime);
  }

  get checkinItems() {
    return this.waitingCustomerList
      .filter((item: any) => item.status === ActivitiesStatus.Checkin)
      .sort(orderByExpectedTime);
  }

  get getServices() {
    return this.services.map((item) => {
      return {
        ...item,
        name: item.name,
      };
    });
  }

  setTable = (tableId?: string) => {
    this.table = this.tableAvailables?.find(({ id }) => id === tableId);
  };

  fetchCustomers = async () => {
    try {
      const { data } = await operatorApi.getCustomers();
      runInAction(() => (this.customerInfoList = data));
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchAvailableTable = async () => {
    try {
      const { data } = await operatorApi.getAvailableTables();
      runInAction(() => {
        this.tableAvailables = data.map(({ userId, table, nickName }) => {
          return { id: userId, name: nickName, table };
        });
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchActivities = async () => {
    try {
      const { data } = await operatorApi.getActivities({
        status: [ActivitiesStatus.Checkin, ActivitiesStatus.Ready],
      });

      runInAction(() => {
        const activities = transformActivities(data);
        this.waitingCustomerList = activities;

        const customers: any = {};
        this.customerInfoList.forEach((item) => {
          customers[item.customerCode] = item.name;
        });
        this.waitingCustomerList.map((item) => {
          item.customerName = customers[item.customerCode];
          return item;
        });
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchService = async () => {
    try {
      const response = await operatorApi.getServicesGroupByField(this.shopId, 'category');
      runInAction(() => {
        const services = transformService(response.data);
        this.services = services;
      });
    } catch (error: any) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchStaffs = async () => {
    try {
      const { data } = await operatorApi.getAvailableTables();
      runInAction(() => (this.staffs = data));
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  resetForm = async () => {
    this.services = this.services.map((service) => {
      service.isSelected = false;
      return service;
    });
    this.customer?.reset();
    this.table = undefined;
  };

  checkin = async () => {
    try {
      await operatorApi.checkinForCustomer({
        customerCode: this.customer?.customerCode ? String(this.customer?.customerCode) : undefined,
        table: this.table?.table,
        serviceCategories: this.chosenServices as any,
      });
      this.isFormCheckinShown = false;
      this.resetForm();
    } catch (error: any) {
      this.appStore.notification?.setError(error);
    }
  };

  confirmChangeTable = async (activityId: string, table: number, comment: string) => {
    try {
      await operatorApi.changeTable({
        activityId,
        table,
        comment,
      });

      runInAction(() => {
        const activityIndex = this.waitingCustomerList.findIndex((obj) => obj.id === activityId);
        this.waitingCustomerList[activityIndex].staff.table = table;
        this.isFormChangeTableShown = false;
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  confirmDeleteActivity = async (activityId: string, comment: string) => {
    try {
      await operatorApi.cancelActivity(activityId, comment);
      runInAction(() => (this.waitingCustomerList = this.waitingCustomerList.filter((item) => item.id !== activityId)));
      this.isFormDeleteActivityShown = false;
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  startService = async (activityId: string) => {
    try {
      await operatorApi.startActivity(activityId);
      runInAction(() => (this.waitingCustomerList = this.waitingCustomerList.filter((item) => item.id !== activityId)));
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetch = async () => {
    await this.fetchCustomers();
    this.fetchActivities();
  };

  toggleFormCheckin = (value: boolean) => {
    this.isFormCheckinShown = value;
  };

  toggleFormChangeTable = (value: boolean) => {
    this.isFormChangeTableShown = value;
  };

  toggleFormDeleteActivity = (value: boolean) => {
    this.isFormDeleteActivityShown = value;
  };

  get chosenServices() {
    return this.services
      .filter(({ isSelected }) => isSelected)
      .map(({ id, name }) => {
        return {
          id,
          name,
        };
      });
  }
}
