import CustomerWaitingList from '@mui/icons-material/ArtTrack';
import Payment from '@mui/icons-material/AttachMoney';
import Booking from '@mui/icons-material/Book';
import Statistic from '@mui/icons-material/DonutSmall';
// import WorkingSchedule from '@mui/icons-material/EventNote';
import UseeCheck from '@mui/icons-material/HowToReg';
// import RegenerateSchedule from '@mui/icons-material/NextWeek';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CustomerServingList from '@mui/icons-material/PlaylistAddCheck';
import SettingsIcon from '@mui/icons-material/Settings';

import {
  bookingUrl,
  dailyIncome,
  payment,
  registerCustomer,
  servingCustomer,
  setting,
  staffCheckedIn,
  staffCheckedInForm,
} from '../../../routers/paths';
import { IMenuList } from '../../molecules/drawer';

export const menu: IMenuList[] = [
  {
    link: '/',
    title: 'menu.title.customer-waiting-list',
    icon: <CustomerWaitingList />,
  },
  {
    link: servingCustomer,
    title: 'menu.title.customer-serving-list',
    icon: <CustomerServingList />,
  },
  {
    link: bookingUrl,
    title: 'menu.title.booking',
    icon: <Booking />,
  },
  // {
  //   link: '/',
  //   title: 'menu.title.working-schedule',
  //   icon: <WorkingSchedule />,
  // },
  // {
  //   link: '/',
  //   title: 'menu.title.regenerate-schedule',
  //   icon: <RegenerateSchedule />,
  // },
  {
    link: staffCheckedInForm,
    title: 'menu.title.staff-checkin',
    icon: <UseeCheck />,
  },
  {
    link: staffCheckedIn,
    title: 'menu.title.checked-in-staff',
    icon: <UseeCheck />,
  },
  {
    link: dailyIncome,
    title: 'menu.title.daily-income',
    icon: <Statistic />,
  },
  {
    link: payment,
    title: 'menu.title.payment',
    icon: <Payment />,
  },
  {
    link: setting,
    title: 'menu.title.settings',
    icon: <SettingsIcon />,
  },
  {
    link: registerCustomer,
    title: 'menu.title.register-customer',
    icon: <PersonAddIcon />,
  },
];
