import { operatorApi } from '@mtt-nails/apis/dist/v3';
import { makeAutoObservable, runInAction } from 'mobx';

import i18n from '../../languages/i18n';
import { StaffStore } from '../../store/domains/StaffStore';

class Staff {
  username = '';
  password = '';

  constructor() {
    makeAutoObservable(this);
  }

  setUsername = (username: string) => (this.username = username);
  setPassword = (password: string) => (this.password = password);
}

export class Store {
  staff: Staff;
  isSubmitting = false;

  constructor(private staffStore: StaffStore) {
    makeAutoObservable(this);
    this.staff = new Staff();
  }

  checkIn = async (username: string, password: string) => {
    try {
      this.isSubmitting = true;
      const { data } = await operatorApi.checkinForStaff({ username, password });
      this.staffStore.root.appStore.notification?.setSuccess(i18n.t('app.notification.check-in.successfully'));
      this.staffStore.setStaff(data);
    } catch (error) {
      this.staffStore.root.appStore.notification?.setError(error);
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  };
}
