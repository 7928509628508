import { RootStore } from '../RootStore';

export enum StorageKey {
  ACCESS_TOKEN = '__access_token__',
  DEFAULT_LANGUAGE = '__default_language__',
}

export class StorageStore {
  constructor(private rootStore?: RootStore) {}

  saveToken(token: string) {
    localStorage.setItem(StorageKey.ACCESS_TOKEN, token);
  }
  getToken(): string {
    return localStorage.getItem(StorageKey.ACCESS_TOKEN) || '';
  }
  clear() {
    localStorage.clear();
  }
  getDefaultLanguage() {
    return localStorage.getItem(StorageKey.DEFAULT_LANGUAGE) ? localStorage.getItem(StorageKey.DEFAULT_LANGUAGE) : 'en';
  }
  setDefaultLanguage(language: string) {
    localStorage.setItem(StorageKey.DEFAULT_LANGUAGE, language);
  }
}
