import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ILanguageSetting {
  changeLanguage?: (language: string) => void;
  defaultLanguage?: () => string;
}

export const LanguageSettings: React.FC<ILanguageSetting> = ({ changeLanguage, defaultLanguage }) => {
  const { t, i18n } = useTranslation();

  const onChangeLanguages = (language: string) => {
    i18n.changeLanguage(language);
    if (changeLanguage) changeLanguage(language);
  };

  return (
    <FormControl>
      <FormLabel>{t('setting.language')}</FormLabel>
      <RadioGroup defaultValue={defaultLanguage} name="radio-buttons-group">
        <FormControlLabel
          value="vi"
          onChange={() => onChangeLanguages('vi')}
          control={<Radio />}
          label={t('setting.language.vietnam')}
        />
        <FormControlLabel
          value="en"
          onChange={() => onChangeLanguages('en')}
          control={<Radio />}
          label={t('setting.language.english')}
        />
      </RadioGroup>
    </FormControl>
  );
};
