import { makeAutoObservable } from 'mobx';

import { Staff } from '../objects/Staff';
import { RootStore } from '../RootStore';

export class StaffStore {
  staff?: Staff | null;

  constructor(public root: RootStore) {
    makeAutoObservable(this);
  }

  setStaff(staff: Staff) {
    this.staff = staff;
  }

  get isStaffCheckin() {
    return !!this.staff;
  }
}
