import { Route, Routes } from 'react-router-dom';

import {
  BookingPage,
  DailyIncomePage,
  HomePage,
  LoginPage,
  NotFoundPage,
  PaymentPage,
  RegisterCustomerPage,
  ServingCustomerPage,
  SettingPage,
  StaffCheckedInPage,
  StaffCheckInPage,
} from '../pages';
import * as paths from './paths';
import { PrivateRoute } from './PrivateRoute';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={paths.login} element={<LoginPage />} />
      <Route path={paths.baseUrl} element={<PrivateRoute />}>
        <Route path={paths.staffCheckedIn} element={<StaffCheckedInPage />} />
        <Route path={paths.staffCheckedInForm} element={<StaffCheckInPage />} />
        <Route path={paths.home} element={<HomePage />} />
        <Route path={paths.servingCustomer} element={<ServingCustomerPage />} />
        <Route path={paths.registerCustomer} element={<RegisterCustomerPage />} />
        <Route path={paths.dailyIncome} element={<DailyIncomePage />} />
        <Route path={paths.payment} element={<PaymentPage />} />
        <Route path={paths.setting} element={<SettingPage />} />
        <Route path={paths.bookingUrl} element={<BookingPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
