import { Address, Contact } from '@mtt-nails/apis';
import { Operator, operatorApi } from '@mtt-nails/apis/dist/v3';
import { makeAutoObservable, runInAction } from 'mobx';

import { RootStore } from '../RootStore';

export interface IConfigShop {
  autoGenSchedule?: boolean;
  enableApp?: boolean;
  enableSelectService?: boolean;
  enableSelectStaff?: boolean;
  orderTurn?: string;
}
export class ShopStore {
  shopId = '';
  name?: string;
  address?: Address;
  banners?: string[];
  contact?: Contact;
  config?: IConfigShop;
  openingTimes?: any;
  staffs: Operator.Staff.Short[] = [];

  constructor(private root: RootStore) {
    makeAutoObservable(this);
    this.fetch();
  }

  get listStaffs() {
    return this.staffs;
  }

  fetch = async () => {
    try {
      const isAuthenticated = this.root.authStore.isAuthenticated;
      if (isAuthenticated) {
        const { data } = await operatorApi.getGrantedShop();
        const staff = await operatorApi.getStaffs(data.id);
        runInAction(() => {
          this.shopId = data.id;
          this.banners = data.banners;
          this.address = data.address;
          this.config = data.config;
          this.contact = data.contact;
          this.openingTimes = data.openingTimes;
          this.name = data.name;
          this.staffs = staff.data;
        });
      }
    } catch (error) {
      this.root.authStore.deAuthorize();
      this.root.appStore.notification?.setError(error);
    }
  };
}
