import { CustomerType } from '@mtt-nails/consts';
import { Box, styled } from '@mui/material';
import { BoxProps } from '@mui/system';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { baseUrl } from '../../../routers/paths';
import { Button, Input, IStepper } from '../../atom';
import { PhoneNumberInput } from '../../atom/phone-input';
import Stepper from '../../atom/stepper';
import { InputBirthday, SummaryRegisterCustomer } from '../../molecules';
import InputFullName from '../../molecules/input-fullname';
import PopupRegisterSuccessfully from '../../molecules/pop-up-register-customer-success';

interface IRegisterCustomer {
  onHandleRegister: (
    firstName: string,
    lastName: string,
    email?: string,
    birthOfDate?: Date,
    phone?: any,
    customerType?: CustomerType,
  ) => void;
  onHandleAlert: (err: any) => void;
  customerCode: number;
}

const BoxWrapComp = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SubBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 50,
}));

export const RegisterCustomer: React.FC<IRegisterCustomer> = ({ onHandleRegister, onHandleAlert, customerCode }) => {
  const { t } = useTranslation();
  const history = useNavigate();

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [countryCode, setCountryCode] = React.useState<string | number>('vn');
  const [email, setEmail] = React.useState<string>('');
  const [isStudent, setIsStudent] = React.useState<boolean>(false);
  const [birthday, setBirthday] = React.useState<Date | null>(new Date(new Date().toISOString().split('T')[0]));

  const [openPopup, setOpenPopup] = React.useState<boolean>(false);

  const handelChangeInputNumber = (phone: string, countryCode: number) => {
    setPhoneNumber(phone);
    setCountryCode(countryCode);
  };

  const onHandleChangeFirstName = (v: string) => {
    setFirstName(v);
  };

  const onHandleChangeLastName = (v: string) => {
    setLastName(v);
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  const listSteps: IStepper['listSteps'] = [
    {
      title: t('register.form.name'),
      component: (
        <InputFullName
          firstName={firstName}
          lastName={lastName}
          onHandleChangeFirstName={onHandleChangeFirstName}
          onHandleChangeLastName={onHandleChangeLastName}
        />
      ),
    },
    {
      title: t('register.form.phone-number'),
      component: (
        <BoxWrapComp>
          <Box sx={{ display: 'inline' }}>
            <PhoneNumberInput countryCode={countryCode} phoneNumber={phoneNumber} onChange={handelChangeInputNumber} />
          </Box>
        </BoxWrapComp>
      ),
    },
    {
      title: t('register.form.email'),
      component: (
        <BoxWrapComp>
          <Box sx={{ width: '30%' }}>
            <Input
              fullWidth
              variant="standard"
              name="email"
              placeholder={t('register.form.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
        </BoxWrapComp>
      ),
    },
    {
      title: t('register.form.birthday'),
      component: (
        <InputBirthday
          time={birthday}
          isStudent={isStudent}
          onHandelCheckbox={(e) => setIsStudent(e.target.checked)}
          onHandelChangeTime={(value) => setBirthday(value)}
        />
      ),
    },
    {
      title: t('register.form.summary'),
      component: (
        <BoxWrapComp>
          <Box sx={{ width: '40%' }}>
            <SummaryRegisterCustomer
              fullName={firstName && lastName ? `${firstName} ${lastName}` : ''}
              phoneNumber={phoneNumber ? `+${phoneNumber}` : ''}
              email={email || ''}
              displayEditIcons
              birthday={dayjs(String(birthday)).format('DD/MM/YYYY')}
              onHandleStep={handleStep}
            />
          </Box>
        </BoxWrapComp>
      ),
    },
  ];

  const totalSteps = () => {
    return listSteps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handelSubmit = () => {
    if (firstName && lastName) {
      onHandleRegister(
        firstName,
        lastName,
        email,
        birthday!,
        { dialing_code: countryCode, number: phoneNumber },
        isStudent ? CustomerType.Student : CustomerType.Customer,
      );
      setOpenPopup(true);
    } else {
      onHandleAlert(t('register.customer.notification.full-name-empty'));
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
        <Box sx={{ width: '80%' }}>
          <Stepper listSteps={listSteps} activeStep={activeStep} onHandleStep={handleStep} />
          {activeStep === 0 && (
            <BoxWrapComp sx={{ marginTop: 5 }}>
              <Box sx={{ width: '30%' }}>
                <Button fullWidth label={t('button.next')} variant="contained" onClick={handleNext} />
              </Box>
            </BoxWrapComp>
          )}
          {activeStep !== 0 && !isLastStep() && (
            <BoxWrapComp>
              <Box sx={{ width: '50%' }}>
                <SubBox>
                  <Box sx={{ width: '40%' }}>
                    <Button
                      fullWidth
                      label={t('button.prev')}
                      variant="contained"
                      color="secondary"
                      onClick={handleBack}
                    />
                  </Box>
                  <Box sx={{ width: '40%' }}>
                    <Button fullWidth label={t('button.next')} variant="contained" onClick={handleNext} />
                  </Box>
                </SubBox>
              </Box>
            </BoxWrapComp>
          )}
          {activeStep !== 0 && isLastStep() && (
            <BoxWrapComp>
              <Box sx={{ width: '50%' }}>
                <SubBox>
                  <Box sx={{ width: '40%' }}>
                    <Button
                      fullWidth
                      label={t('button.prev')}
                      color="secondary"
                      variant="contained"
                      onClick={handleBack}
                    />
                  </Box>
                  <Box sx={{ width: '40%' }}>
                    <Button fullWidth label={t('button.register')} variant="contained" onClick={handelSubmit} />
                  </Box>
                </SubBox>
              </Box>
            </BoxWrapComp>
          )}
        </Box>
      </Box>
      <PopupRegisterSuccessfully
        fullName={`${firstName} ${lastName}`}
        phoneNumber={phoneNumber ? `+${phoneNumber}` : ''}
        email={email}
        customerCode={customerCode}
        birthday={dayjs(String(birthday)).format('DD/MM/YYYY')}
        openPopup={openPopup}
        onHandleClose={() => {
          setOpenPopup(false);
          history(baseUrl);
        }}
      />
    </>
  );
};
