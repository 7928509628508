import { Operator, operatorApi } from '@mtt-nails/apis/dist/v3';
import { CurrencyTypes } from '@mtt-nails/consts';
import { makeAutoObservable, runInAction } from 'mobx';

import { AppStore } from '../../store/domains/AppStore';
import { ShopStore } from '../../store/domains/ShopStore';

export type Income = {
  value: string | number;
  unit: CurrencyTypes;
};

export interface IStaff {
  userId: string;
  name: string;
  customersAmount: string;
  income: Income;
}

export class Staff {
  userId: string;
  name: string;
  customersAmount: string;
  income: Income;

  constructor(activity: IStaff) {
    makeAutoObservable(this);
    this.userId = activity.userId;
    this.name = activity.name;
    this.customersAmount = activity.customersAmount;
    this.income = activity.income;
  }
}

export class Store {
  selectedStaff: string;
  customers: Operator.Statistic.CustomerActivity[];
  staffs: Staff[];
  shopId: string;

  constructor(private appStore: AppStore, shopStore: ShopStore) {
    makeAutoObservable(this);
    this.customers = [];
    this.staffs = [];
    this.selectedStaff = '';
    this.shopId = shopStore.shopId;
    this.getStaffIncomeStatistic();
  }

  get selectedStaffIncome() {
    const staff = this.staffs.find((s) => s.userId === this.selectedStaff);
    if (!staff) {
      return {};
    }
    return staff.income;
  }

  get statistic() {
    let customersAmount = 0;
    let totalIncome = 0;
    let unit = '';

    this.staffs.forEach((staff) => {
      if (staff.income && staff.income.value) {
        customersAmount = +staff.customersAmount + customersAmount;
        totalIncome = +staff.income.value + totalIncome;
        unit = staff.income.unit;
      }
    });
    return {
      customersAmount,
      totalIncome,
      unit,
    };
  }

  setSelectedStaff = (staffId: string) => {
    this.selectedStaff = staffId;
  };

  getCustomerServed = async () => {
    try {
      if (this.selectedStaff !== null) {
        const { data } = await operatorApi.getCustomerServedByStaffStatistic({
          shopId: this.shopId,
          staffId: this.selectedStaff,
        });
        runInAction(() => (this.customers = data));
      }
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  getStaffIncomeStatistic = async () => {
    try {
      const { data } = await operatorApi.getDailyIncomeStatistic(this.shopId);
      runInAction(() => {
        this.staffs = data.map((s) => new Staff(s));
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetch = async () => {
    await this.getStaffIncomeStatistic();
  };
}
