import { CurrencyTypes } from '@mtt-nails/consts';
import { Box, styled, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE } from '../../../libs/constants';
import { symbolUnit } from '../../../libs/utils';

interface IServiceDetailProps {
  serviceName?: { [key: string]: string };
  price?: number;
  quantity?: number;
}

export interface IInvoiceDetailProps {
  queueNumber?: string;
  customerName?: string;
  customerCode?: number;
  serviceDetails?: IServiceDetailProps[];
  promo?: number;
  offer?: number;
  tax?: number;
  totalPrice?: number;
  currency: CurrencyTypes;
}

const WrapContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(2),
}));

const WrapDetailItems = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: 5,
}));

const WrapTitle = styled(Box)(() => ({ width: '250px' }));

export const InvoiceDetail: React.FC<IInvoiceDetailProps> = ({
  queueNumber,
  customerName,
  customerCode,
  serviceDetails,
  promo,
  offer,
  tax,
  totalPrice,
  currency,
}) => {
  const { t } = useTranslation();
  return (
    <WrapContainer>
      <WrapDetailItems>
        <WrapTitle>
          <Typography variant="h6">{t('payment.invoice.queue-number')}:</Typography>
        </WrapTitle>
        <Typography variant="h6" ml={2}>
          {queueNumber}
        </Typography>
      </WrapDetailItems>
      <WrapDetailItems>
        <WrapTitle>
          <Typography variant="h6">{t('component.organisms.customer-checkin-model.customer-name')}:</Typography>
        </WrapTitle>
        <Typography variant="h6">{customerName || t('invoice.customer.anonymous')}</Typography>
      </WrapDetailItems>
      <WrapDetailItems>
        <WrapTitle>
          <Typography variant="h6">{t('component.organisms.customer-checkin-model.customer-code')}:</Typography>
        </WrapTitle>
        <Typography variant="h6">{customerCode || t('invoice.customer.anonymous')}</Typography>
      </WrapDetailItems>
      <Box mt={3} mb={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('payment.invoice.services-name')}</TableCell>
                <TableCell align="right">{t('payment.invoice.services-price')}</TableCell>
                <TableCell align="right">{t('payment.invoice.quantity')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceDetails &&
                serviceDetails.map((row, i) => (
                  <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.serviceName?.[DEFAULT_LANGUAGE]}
                    </TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <WrapDetailItems>
        <WrapTitle>
          <Typography variant="h6">{t('payment.invoice.promo')}:</Typography>
        </WrapTitle>
        <Typography variant="h6">
          {promo} {symbolUnit(currency)}
        </Typography>
      </WrapDetailItems>
      <WrapDetailItems>
        <WrapTitle>
          <Typography variant="h6">{t('payment.invoice.offer')}:</Typography>
        </WrapTitle>
        <Typography variant="h6">
          {offer} {symbolUnit(currency)}
        </Typography>
      </WrapDetailItems>
      <WrapDetailItems>
        <WrapTitle>
          <Typography variant="h6">{t('payment.invoice.tax')}:</Typography>
        </WrapTitle>
        <Typography variant="h6">{tax} %</Typography>
      </WrapDetailItems>
      <WrapDetailItems>
        <WrapTitle>
          <Typography variant="h5">{t('payment.invoice.total')}:</Typography>
        </WrapTitle>
        <Typography variant="h5">
          {totalPrice} {symbolUnit(currency)}
        </Typography>
      </WrapDetailItems>
    </WrapContainer>
  );
};
