import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atom';
import ListActivities from './listActivities';
import { IWaitingListCustomerProps } from './types';

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.5rem',
}));

const WaitingListCustomer: React.FC<IWaitingListCustomerProps> = ({
  store,
  onClickChangeTable,
  onClickDeleleItem,
  onClickStartService,
  onClickGetNumberForCustomer,
  onClickRegisterForCustomer,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%" paddingY={2} alignItems="center">
        <PageTitle>{t('page.waiting-customer.title')}</PageTitle>
        <Box display="flex" justifyContent="right" width="50%">
          <Box width="45%" alignSelf="center" paddingRight={0.5}>
            <Button
              label={t('page.waiting-customer.button.register')}
              onClick={onClickRegisterForCustomer}
              fullWidth
              color="primary"
              variant="contained"
            />
          </Box>
          <Box width="45%" alignSelf="center">
            <Button
              label={t('page.waiting-customer.button.get-number')}
              fullWidth
              onClick={onClickGetNumberForCustomer}
              color="secondary"
              variant="contained"
            />
          </Box>
        </Box>
      </Box>
      <ListActivities
        shopStore={store}
        onClickChangeTable={onClickChangeTable}
        onClickDeleleItem={onClickDeleleItem}
        onClickStartService={onClickStartService}
      />
    </>
  );
};
export default WaitingListCustomer;
