import { Box, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '../../atom';

const WrapOfferInput = styled(Box)(() => ({
  width: '100%',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderOfferInput = styled(Box)(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  textAlign: 'center',
  padding: '16px',
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light} , ${theme.palette.primary.main})`,
}));

const BodyOfferInput = styled(Box)(() => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: '32px',
  backgroundColor: 'white',
  transition: 'all ease 0.5s',
  '&:hover': {
    overflowY: 'auto',
  },
}));

const FooterOfferInput = styled(Box)(() => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: '16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface IOfferInputProps {
  onOfferClick?: (value: number) => void;
}

export const OfferInput: React.FC<IOfferInputProps> = ({ onOfferClick }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<number>(0);

  return (
    <WrapOfferInput>
      <HeaderOfferInput>
        <Typography variant="h5" color="white">
          {t('payment.offer-input.enter-offer-input')}
        </Typography>
      </HeaderOfferInput>
      <BodyOfferInput>
        <Input
          fullWidth
          type="number"
          placeholder={t('payment.offer-input.enter-offer-input')}
          onChange={(e) => setValue(+e.target.value)}
        />
      </BodyOfferInput>
      <FooterOfferInput>
        <Button
          label={t('payment.offer-input.add-now')}
          color="primary"
          onClick={() => onOfferClick?.(value)}
          type="button"
          variant="contained"
        />
      </FooterOfferInput>
    </WrapOfferInput>
  );
};
