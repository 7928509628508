import { Box, styled, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { BoxProps } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IInputBirthday {
  isStudent: boolean;
  time: Date | null;
  onHandelCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHandelChangeTime: (value: Date | null) => void;
}

const BoxTitle = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 10,
  boxShadow: `1px 2px ${theme.palette.grey[500]}`,
  border: `1px solid ${theme.palette.grey[500]}`,
}));

export const InputBirthday: React.FC<IInputBirthday> = ({ time, isStudent, onHandelCheckbox, onHandelChangeTime }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ width: '40%' }}>
        <BoxTitle>
          <Typography color="primary">{t('intro.register.form')}</Typography>
        </BoxTitle>
        <Box>
          <BoxTitle sx={{ marginTop: 2 }}>
            <Typography sx={{ display: 'inline' }}>{t('intro.register.confirm-student')}:</Typography>
            <Checkbox checked={isStudent} color="secondary" onChange={onHandelCheckbox} />
          </BoxTitle>
        </Box>
      </Box>
      <Box sx={{ width: '40%' }}>
        <Typography>{t('intro.register.choose-birthday')}</Typography>
        <Box mt={2} sx={{ width: '80%' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={time}
              onChange={onHandelChangeTime}
              disableFuture
              renderInput={(params) => <TextField fullWidth {...params} />}
              inputFormat="dd-MM-yyyy"
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};
