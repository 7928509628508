import { Operator } from '@mtt-nails/apis/dist/v3';
import { Box, Grid, styled, Typography as TypographyMui } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TWaitingListCustomer } from '../../../pages/home/types';
import { Button, Input, Selects } from '../../atom';
import ItemTableForm from './ItemTableForm';

const Wrapper = styled(Box)({
  width: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 36,
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, rgb(229, 79, 124, 0.5))`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.grey[50],
}));

const Typography = styled(TypographyMui)(() => ({
  fontSize: 22,
}));

interface IChangeTableForm {
  staffs: Operator.Staff.Short[];
  activity: TWaitingListCustomer;
  handleConfirmChangeTable: (activityId: string, table: number, comment: string) => void;
  onCancel: () => void;
}

const ChangeTableForm: React.FC<IChangeTableForm> = observer(
  ({ staffs, handleConfirmChangeTable, activity, onCancel }) => {
    const [newTable, setNewTable] = useState('');
    const [comment, setComment] = useState('');
    const { t } = useTranslation();

    return (
      <Wrapper>
        <Header>{t('component.organisms.change-table-form.header')}</Header>
        <Box p={2}>
          <Grid container spacing={1} flexDirection="column">
            <Box mt={10} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.change-table-form.title.book-number')}>
                    <Typography>
                      {typeof activity?.bookNumber === 'string'
                        ? new Date(activity?.bookNumber).toISOString().substring(11, 19)
                        : activity?.bookNumber.toISOString().substring(11, 19)}
                    </Typography>
                  </ItemTableForm>
                </Grid>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.change-table-form.title.customer-code')}>
                    <Typography>{activity?.customerCode}</Typography>
                  </ItemTableForm>
                </Grid>
              </Grid>
            </Box>
            <Box mt={10} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.change-table-form.title.current-table')}>
                    <Typography>{activity?.currentTable}</Typography>
                  </ItemTableForm>
                </Grid>
                <Grid item xs={6}>
                  <ItemTableForm title={t('component.organisms.change-table-form.title.expected-table')}>
                    <Selects
                      label=""
                      values={staffs.map((staff) => {
                        return { label: `${staff.table}`, value: `${staff.table}` };
                      })}
                      onChange={(value) => setNewTable(value || `${activity.currentTable}`)}
                      defaultValue={{ label: `${activity.currentTable}`, value: `${activity.currentTable}` }}
                    />
                  </ItemTableForm>
                </Grid>
              </Grid>
            </Box>
            <Box mt={10} width="100%">
              <Input
                fullWidth
                multiline
                maxRows={10}
                minRows={4}
                placeholder={t('component.organisms.change-table-form.title.comment')}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
            <Box mt={10} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('component.organisms.change-table-form.button.confirm')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => handleConfirmChangeTable(activity.activityId, +newTable, comment)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('component.organisms.change-table-form.button.close')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={onCancel}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Wrapper>
    );
  },
);

export default ChangeTableForm;
