import { Alert, AlertProps, Snackbar as SnackbarMui } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useAppStore } from '../../../providers/StoreProvider';

export type NotificationStatus = AlertProps['severity'];

const Snackbar: React.FC = observer(() => {
  const appStore = useAppStore();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    appStore.notification?.reset();
  };

  return (
    <SnackbarMui
      open={!!appStore.notification?.message}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={appStore.notification?.status || undefined} sx={{ width: '100%' }}>
        {appStore.notification?.message}
      </Alert>
    </SnackbarMui>
  );
});

export default Snackbar;
