import { get, isObject } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { NotificationStatus } from '../../components/atom/snackbar';
import i18n from '../../languages/i18n';
import { RootStore } from '../RootStore';

export class Notification {
  status?: NotificationStatus | null;
  message?: string | null;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.status = null;
    this.message = null;
  };

  setError = (error: any) => {
    this.message = `${i18n.t('app.notification.error')}: ${i18n.t(this.__getMessage(error))}`;
    this.status = 'error';
  };

  setSuccess = (success: any) => {
    this.message = `${i18n.t('app.notification.success')}: ${i18n.t(this.__getMessage(success))}`;
    this.status = 'success';
  };

  setWaring = (notification: any) => {
    this.message = `${i18n.t('app.notification.warning')}: ${i18n.t(this.__getMessage(notification))}`;
    this.status = 'warning';
  };

  setInfo = (info: any) => {
    this.message = `${i18n.t('app.notification.info')}: ${i18n.t(this.__getMessage(info))}`;
    this.status = 'info';
  };

  __getMessage = (data: any) => {
    let msg = '';
    if (typeof data === 'string') {
      msg = data;
    } else if (this.__errorFormApi(data)) {
      msg = get(data, 'response.data.message');
    } else if (data && isObject(data) && 'message' in data) {
      const { message } = data as { message: string };
      msg = message;
    }
    return msg.replace(/"/g, "'");
  };

  __errorFormApi = (error: any) => {
    return isObject(error) && 'config' in error && 'request' in error && 'response' in error;
  };
}

export class AppStore {
  notification: Notification | null = null;

  constructor(private root: RootStore) {
    makeAutoObservable(this);
    this.notification = new Notification();
  }
}
