import { Button as MuiButton, ButtonProps } from '@mui/material';
import React from 'react';

interface IButtonProps {
  label: string;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  type?: ButtonProps['type'];
  size?: ButtonProps['size'];
  onClick?: ButtonProps['onClick'];
  fullWidth?: boolean;
  startIcon?: ButtonProps['startIcon'];
  endIcon?: ButtonProps['endIcon'];
  background?: string;
  disabled?: boolean;
  borderRadius?: number;
}

export const Button: React.FC<IButtonProps> = ({ label, background, borderRadius, ...rest }) => {
  return (
    <MuiButton sx={{ background, borderRadius }} {...rest}>
      {label}
    </MuiButton>
  );
};
