import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { theme } from '../../../libs/themes';
import { translate } from '../../../libs/utils/translate';
import { Button } from '../../atom/button';
import { Input } from '../../atom/input';
import { QrcodeStaffCheckin } from '../qrcode-checkin';

export interface ICheckinFormProps {
  shopId?: string;
  username?: string;
  password?: string;
  onUsernameChange?: (username: string) => void;
  onPasswordChange?: (password: string) => void;
  onCheckin?: (username: string, password: string) => void;
}

const WrapContainerStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const WrapCheckinStyles = styled(Box)(({ theme }) => ({
  width: '40%',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '20px',
  boxSizing: 'border-box',
  borderRadius: '10px',
  padding: '6%',
  backgroundColor: theme.palette.background.paper,
}));

const WrapFormStyles = styled(Box)(() => ({
  width: '100%',
  borderRadius: '10px',
  display: 'flex',
  padding: '10px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const WrapInputStyles = styled(Box)(() => ({
  width: '100%',
  minHeight: '100px',
  borderRadius: '10px',
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
}));
interface IFormCheckin {
  username: string;
  password: string;
}

const schema = yup.object().shape({
  username: yup.string().required(
    translate('validations.required', {
      field: translate('fields.username'),
    }),
  ),
  password: yup.string().required(
    translate('validations.required', {
      field: translate('fields.password'),
    }),
  ),
});

export const CheckinForm: React.FC<ICheckinFormProps> = ({ shopId, username, password, onCheckin }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState<{ icon: React.ReactNode; isShow: boolean }>({
    icon: <Visibility />,
    isShow: false,
  });

  const qrcode = JSON.stringify({
    time: new Date().getTime(),
    code: shopId,
  });

  const handleSetShowPassword = () => {
    !showPassword.isShow
      ? setShowPassword({
          icon: <VisibilityOff />,
          isShow: true,
        })
      : setShowPassword({
          icon: <Visibility />,
          isShow: false,
        });
  };

  const defaultValues = {
    username: username || '',
    password: password || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormCheckin>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const username_ = React.useMemo(() => register('username'), [register]);
  const password_ = React.useMemo(() => register('password'), [register]);

  const handleOnSubmit = ({ username, password }: IFormCheckin) => {
    if (username && password && onCheckin) onCheckin(username, password);
  };
  return (
    <WrapContainerStyles>
      <WrapCheckinStyles>
        <QrcodeStaffCheckin qrcode={qrcode} />
        <Typography mb={4} mt={4} variant="h6" component="div" gutterBottom>
          {t('page.home.or-check-in-with-form')}
        </Typography>

        <WrapFormStyles>
          <form
            style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <WrapInputStyles>
              <Typography variant="subtitle1">{t('page.home.username')}</Typography>
              <Input
                fullWidth
                {...username_}
                placeholder={t('page.home.username')}
                errorMessage={errors.username?.message}
              />
            </WrapInputStyles>
            <WrapInputStyles>
              <Typography variant="subtitle1">{t('page.home.password')}</Typography>
              <Input
                fullWidth
                type={!showPassword.isShow ? 'password' : 'text'}
                {...password_}
                placeholder={t('page.home.password')}
                errorMessage={errors.username?.message}
                endIcon={showPassword.icon}
                endIconClick={handleSetShowPassword}
              />
            </WrapInputStyles>

            <Box
              sx={{
                width: '100%',
                marginTop: '20px',
                boxSizing: 'border-box',
              }}
            >
              <Button
                label="Check in"
                fullWidth
                background={theme.palette.primary.main}
                onClick={handleSubmit(handleOnSubmit)}
                type="submit"
                variant="contained"
              />
            </Box>
          </form>
        </WrapFormStyles>
      </WrapCheckinStyles>
    </WrapContainerStyles>
  );
};
