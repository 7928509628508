import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  Drawer as DrawerMui,
  List,
  ListItem as ListItemMui,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const Image = styled(Box)(({ src }: { src: string }) => ({
  width: '100%',
  height: 300,
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const Divider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 50,
  backgroundColor: theme.palette.primary.main,
}));

const Logout = styled(ListItemMui)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderTop: `1px solid ${theme.palette.grey[100]}`,
}));

const Link = styled(RouterLink)(({ theme }) => ({
  width: '100%',
  textDecoration: 'none',
  color: theme.palette.grey[900],
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export interface IMenuList {
  title: string;
  icon: React.ReactNode;
  link: string;
}

export interface IDrawer {
  open: boolean;
  onClose: () => void;
  menuList: IMenuList[];
  imageLink: string;
  onLogout: () => void;
}

const Drawer: React.FC<IDrawer> = ({ open, onClose, menuList, imageLink, onLogout }) => {
  const { t } = useTranslation();
  return (
    <DrawerMui open={open} onClose={onClose}>
      <Box width={400}>
        <Image src={imageLink} />
        <Divider />
        <List sx={{ width: '100%' }} aria-label="contacts">
          {menuList.map(({ icon, title, link }, i) => {
            return (
              <ListItemMui disablePadding key={i}>
                <Link to={link}>
                  <ListItemButton>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={t(title)} />
                  </ListItemButton>
                </Link>
              </ListItemMui>
            );
          })}
          <Logout disablePadding onClick={onLogout}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('menu.logout')} color="primary" />
            </ListItemButton>
          </Logout>
        </List>
      </Box>
    </DrawerMui>
  );
};

export default React.memo(Drawer);
