import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import QRCode from 'qrcode.react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IQrcodeStaffCheckinProps {
  qrcode?: string;
}

const WrapContainerStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const QrcodeStaffCheckin: React.FC<IQrcodeStaffCheckinProps> = ({ qrcode }) => {
  const { t } = useTranslation();
  return (
    <WrapContainerStyles>
      <Typography mb={4} variant="h6" component="div" gutterBottom>
        {t('page.home.qr-code-check-in')}
      </Typography>

      <Box
        sx={{
          width: 140,
        }}
      >
        <QRCode value={qrcode || 'https://www.google.com/'} size={128} />,
      </Box>
    </WrapContainerStyles>
  );
};
