import { Box, styled } from '@mui/material';
import * as React from 'react';

import { MenuSetting } from '../../molecules/menu-setting';
import { ChangePassword } from '../../organisms/change-password';
import { LanguageSettings } from '../../organisms/language-setting';

const WrapSetting = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

const Menu = styled(Box)(() => ({
  width: '30%',
}));

const ScreenSide = styled(Box)(() => ({
  width: '69%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const MainScreen = styled(Box)(() => ({
  width: '80%',
  height: '80%',
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface ISettingsProps {
  oldPassword?: string;
  onChangeOldPassword?: (oldPassword: string) => void;
  newPassword?: string;
  onChangeNewPassword?: (newPassword: string) => void;
  confirmPassword?: string;
  defaultLanguage?: () => string;
  onChangeConfirmPassword?: (confirmPassword: string) => void;
  changeLanguage?: (language: string) => void;
  onChangePassword?: (oldPassword: string, newPassword: string, confirmPassword: string) => void;
}

export const Settings: React.FC<ISettingsProps> = ({
  oldPassword,
  confirmPassword,
  newPassword,
  onChangePassword,
  onChangeOldPassword,
  onChangeNewPassword,
  onChangeConfirmPassword,
  changeLanguage,
  defaultLanguage,
}) => {
  const [curMainScreen, setCurMainScreen] = React.useState('language');
  const ListSettingsOptions = [
    {
      title: 'setting.language',
      isChoose: true,
      onClick: () => setCurMainScreen('language'),
    },
    {
      title: 'setting.printer',
      isChoose: false,
      onClick: () => setCurMainScreen('printer'),
    },
    {
      title: 'setting.password-setting',
      isChoose: false,
      onClick: () => setCurMainScreen('password'),
    },
  ];
  return (
    <WrapSetting>
      <Menu>
        <MenuSetting listMenuItems={ListSettingsOptions} />
      </Menu>
      <ScreenSide>
        <MainScreen>
          {curMainScreen === 'language' && (
            <LanguageSettings defaultLanguage={defaultLanguage} changeLanguage={changeLanguage} />
          )}
          {curMainScreen === 'password' && (
            <ChangePassword
              oldPassword={oldPassword}
              newPassword={newPassword}
              confirmPassword={confirmPassword}
              onChangeOldPassword={onChangeOldPassword}
              onChangeNewPassword={onChangeNewPassword}
              onChangeConfirmPassword={onChangeConfirmPassword}
              onChangePassword={onChangePassword}
            />
          )}
        </MainScreen>
      </ScreenSide>
    </WrapSetting>
  );
};
