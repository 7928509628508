import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, styled, Typography as TypographyMui } from '@mui/material';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { transformTableOption } from '../../../libs/utils/tables';
import { Store } from '../../../pages/home/store';
import { Button, Input, Selects } from '../../atom';
import ListServicesCard from '../../molecules/ListServicesCard';

const Wrapper = styled(Box)({
  width: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 36,
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, rgb(229, 79, 124, 0.5))`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.grey[50],
}));

const WrapService = styled(Box)(({ theme }) => ({
  minHeight: 46,
  padding: 10,
  border: `1px solid ${theme.palette.grey[100]}`,
}));

const Typography = styled(TypographyMui)(({ theme }) => ({
  color: theme.palette.grey[100],
  fontSize: 14,
}));

export type TValue = string | number;

interface ICustomerCheckinModel {
  shopStore: Store;
  enableSelectService?: boolean;
  enableSelectStaff?: boolean;
  onCancel: () => void;
  onSearchCustomer?: (valye: string) => void;
}

const CustomerCheckinModel: React.FC<ICustomerCheckinModel> = observer(
  ({ shopStore, enableSelectStaff, enableSelectService, onSearchCustomer, onCancel }) => {
    const { t } = useTranslation();
    const handleFindCustomerById = (e: any) => {
      onSearchCustomer?.(e.target.value);
    };
    return (
      <Wrapper>
        <Header>{t('component.organisms.customer-checkin-model.header')}</Header>
        <Box p={2}>
          <Grid container spacing={1} flexDirection="column">
            <Grid item>
              <Input
                fullWidth
                type="number"
                variant="outlined"
                endIcon={<SearchIcon />}
                placeholder={t('component.organisms.customer-checkin-model.customer-code')}
                inputProps={{ onBlur: handleFindCustomerById }}
              />
            </Grid>
            {enableSelectStaff ? (
              <Grid item>
                <Selects
                  label={t('component.organisms.customer-checkin-model.selected-planholder')}
                  values={transformTableOption(shopStore?.tableAvailables || [])}
                  defaultValue={{ label: shopStore.table?.name || '', value: shopStore.table?.id || '' }}
                  onChange={(value?: string) => {
                    shopStore?.setTable(value);
                  }}
                />
              </Grid>
            ) : null}
            {enableSelectService ? (
              <Grid item>
                <WrapService>
                  <Typography>{t('component.organisms.customer-checkin-model.services')}</Typography>
                  <ListServicesCard services={shopStore.getServices} />
                </WrapService>
              </Grid>
            ) : null}

            <Observer>
              {() => {
                return (
                  <>
                    {shopStore.customer?.name ? (
                      <Grid item>
                        <WrapService>
                          <Typography>{t('component.organisms.customer-checkin-model.customer-name')}</Typography>
                          <TypographyMui>{shopStore.customer.name}</TypographyMui>
                        </WrapService>
                      </Grid>
                    ) : null}
                  </>
                );
              }}
            </Observer>

            <Box mt={10} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    label={t('component.organisms.customer-checkin-model.get-number')}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={shopStore.checkin}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    label={t('component.organisms.customer-checkin-model.cancel')}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      onCancel();
                      shopStore.resetForm();
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Wrapper>
    );
  },
);

export default CustomerCheckinModel;
