import { Box, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { symbolUnit } from '../../../libs/utils';
import InvoiceDiscount, { IInvoiceDiscount, IPrice } from '../../molecules/invoice-discount';
import InvoiceServiceLine, { IInvoiceServiceLine } from '../../molecules/invoice-service-line';

const Wrapper = styled(Box)(({ theme }) => ({
  height: '40vh',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 5,
  marginTop: theme.spacing(1),
  padding: theme.spacing(1, 4),
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const WrapperListService = styled(Box)(({ theme }) => ({
  maxHeight: '35vh',
  overflow: 'scroll',
  overflowX: 'hidden',
  marginBottom: theme.spacing(4),
  '&::-webkit-scrollbar': {
    backgroundColor: theme.palette.background.paper,
    width: 16,
  },
}));

const TotalPrice = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.primary.main,
  padding: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 5,
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));

interface IInvoiceDetailList {
  invoiceList: IInvoiceServiceLine[];
  invoiceDiscound: IInvoiceDiscount;
  total: IPrice;
  onCheckout?: () => void;
}
const InvoiceDetailList: React.FC<IInvoiceDetailList> = ({ invoiceList, total, onCheckout, invoiceDiscound }) => {
  const { t } = useTranslation();
  return (
    <>
      <TotalPrice>
        <Typography>{t('invoice.list.total')}:</Typography>
        <Typography>
          {total.value}
          {symbolUnit(total.currency)}
        </Typography>
      </TotalPrice>
      <Wrapper>
        <WrapperListService>
          <Grid container flexDirection="column" justifyContent="center" spacing={1}>
            {invoiceList.map((item) => {
              return (
                <Grid item key={item.id}>
                  <InvoiceServiceLine {...item} />
                </Grid>
              );
            })}
          </Grid>
        </WrapperListService>
        <InvoiceDiscount promo={invoiceDiscound.promo} offer={invoiceDiscound.offer} onCheckout={onCheckout} />
      </Wrapper>
    </>
  );
};

export default React.memo(InvoiceDetailList);
