import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryRegisterCustomer } from '../summary-register-customer';

interface IPopupRegisterSuccessfully {
  fullName: string;
  phoneNumber?: string;
  email?: string;
  birthday?: string;
  openPopup: boolean;
  onHandleClose: () => void;
  customerCode?: number;
}

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  fontSize: 18,
  textAlign: 'center',
}));

const PopupRegisterSuccessfully: React.FC<IPopupRegisterSuccessfully> = ({
  fullName,
  phoneNumber,
  email,
  birthday,
  openPopup,
  onHandleClose,
  customerCode,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openPopup}
      onClose={onHandleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Text>{t('register.customer.notification.success')}</Text>
      </DialogTitle>
      <DialogContent>
        <SummaryRegisterCustomer
          customerCode={customerCode}
          fullName={fullName}
          phoneNumber={phoneNumber || ''}
          email={email || ''}
          birthday={birthday || ''}
        />
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 2, cursor: 'pointer' }}>
        <CheckCircleOutlineIcon onClick={onHandleClose} fontSize="large" color="primary" />
      </Box>
    </Dialog>
  );
};

export default PopupRegisterSuccessfully;
