import MenuIcon from '@mui/icons-material/Menu';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';

interface IHeader {
  onMenuClick: () => void;
  phoneNumber?: string;
  shopName?: string;
}
const Header: React.FC<IHeader> = ({ onMenuClick, phoneNumber, shopName }) => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="span" width="100%">
            {shopName}
          </Typography>
          <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
            <Grid item>
              <PhoneInTalkIcon />
            </Grid>
            <Grid item>
              <Typography variant="h6">{phoneNumber}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default React.memo(Header);
