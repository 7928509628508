import { EventNames } from '@mtt-nails/consts';
import socket from '@mtt-nails/socket-events';
import { Dialog } from '@mui/material';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ChangeCodeForm from '../../components/organisms/change-customer-form';
import Layout from '../../components/templates/Layout';
import ServingListCustomer from '../../components/templates/serving-list-customer';
import { useAppStore, useShopStore } from '../../providers/StoreProvider';
import { Store } from './store';

export const ServingCustomerPage: React.FC = () => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const shopStore = useShopStore();
  const store = useLocalObservable(() => new Store(appStore, shopStore));

  const loadData = () => {
    shopStore.fetch();
    store.fetch();
  };
  useEffect(() => {
    loadData();
    socket.registerListener(EventNames.ActivityStarted, loadData);
    socket.registerListener(EventNames.ActivityEnded, loadData);
    socket.registerListener(EventNames.EstimatedTimeUpdated, loadData);
    socket.registerListener(EventNames.ConfirmCode, loadData);
    socket.registerListener(EventNames.ServicesUpdated, loadData);
    return () => {
      socket.unregisterListener(EventNames.ActivityStarted, loadData);
      socket.unregisterListener(EventNames.ActivityEnded, loadData);
      socket.unregisterListener(EventNames.EstimatedTimeUpdated, loadData);
      socket.unregisterListener(EventNames.ConfirmCode, loadData);
      socket.unregisterListener(EventNames.ServicesUpdated, loadData);
    };
  }, []);

  return (
    <Layout title={t('menu.title.customer-serving-list')}>
      <Observer>
        {() => (
          <>
            <ServingListCustomer
              customers={store.servingCustomerList}
              handleChangeCustomerCode={store.getCustomerByCode}
              handleClickFinish={store.endActivity}
            />
            <Dialog
              onClose={() => store.toggleFormChangeCustomerCode(false)}
              open={store.needConfirmChangeCode}
              maxWidth="lg"
              fullWidth
            >
              <ChangeCodeForm
                customerCode={store.newCustomerCode}
                customerName={store.newCustomerName}
                handleConfirmChangeCode={store.applyNewCode}
                onCancel={store.rejectNewCode}
              />
            </Dialog>
          </>
        )}
      </Observer>
    </Layout>
  );
};
