import { CurrencyTypes } from '@mtt-nails/consts';
import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Invoices } from '../../components/organisms/invoices';
import { ListPromotions } from '../../components/organisms/list-promotions';
import { OfferInput } from '../../components/organisms/offer-input';
import Layout from '../../components/templates/Layout';
import Payment from '../../components/templates/payment';
import { useAppStore, useShopStore } from '../../providers/StoreProvider';
import { Store } from './store';

export const PaymentPage: React.FC = observer(() => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const shopStore = useShopStore();
  const store = useLocalObservable(() => new Store(appStore, shopStore));

  return (
    <Layout hasContainer title={t('menu.title.payment')}>
      <Observer>
        {() => {
          return (
            <Payment
              listServiceDetails={store.listServices}
              invoiceList={store.serviceOnInvoice}
              invoiceDiscound={{
                promo: {
                  value: store.invoice?.promotion.value || 0,
                  currency: store.invoice?.promotion.unit || CurrencyTypes.Euro,
                },
                offer: {
                  value: store.invoice?.offer.value || 0,
                  currency: store.invoice?.promotion.unit || CurrencyTypes.Euro,
                },
              }}
              total={{
                value: store?.invoice?.paid || 0,
                currency: store.invoice?.promotion.unit || CurrencyTypes.Euro,
              }}
              listWaiting={store.invoiceList}
              currnetInvoiceWaiting={store.invoice}
              onChangeInvoice={store.setInvoice}
              onCheckout={store.toggleShowInvoice}
              onPromoClick={store.toggleShowPromo}
              onOfferClick={store.toggleShowOffer}
            />
          );
        }}
      </Observer>

      <Dialog open={!!store?.isShowInvoice} fullWidth maxWidth="lg" onClose={store.toggleShowInvoice}>
        <Invoices
          invoiceDetails={{
            queueNumber: dayjs(store?.invoice?.bookingNumber).format('HH:mm:ss'),
            customerName: store.customer?.name,
            customerCode: store.customer?.customerCode,
            promo: store.invoice?.promotion.value,
            offer: store.invoice?.offer.value,
            tax: store.invoice?.tax,
            totalPrice: store.invoice?.paid,
            serviceDetails: store.serviceOnPayment,
            currency: store.invoice?.promotion.unit || CurrencyTypes.Euro,
          }}
          onPaymentClick={store.payment}
        />
      </Dialog>
      <Dialog open={!!store?.isShowPromo} fullWidth maxWidth="lg" onClose={store.toggleShowPromo}>
        <ListPromotions listPromotions={store.listPromotions} onRemoveClick={store.removeInvoicePromotion} />
      </Dialog>
      <Dialog open={!!store?.isShowOffer} fullWidth maxWidth="sm" onClose={store.toggleShowOffer}>
        <OfferInput onOfferClick={store.setInvoiceOffer} />
      </Dialog>
    </Layout>
  );
});

export default PaymentPage;
