import { Avatar, Box, Radio, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export interface IServiceCardProps {
  label: string;
  alt?: string;
  image: string;
  checked?: boolean;
  onClick?: () => void;
}

const HEIGHT_BOX = 80;
const SIZE_IMAGE = 60;
const ALT_DEFAULT = 'service-image';
const SIZE_RADIO = 18;

const ServiceBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  padding: theme.spacing(1.25),
  border: `1px solid ${theme.palette.grey[100]}`,
  height: HEIGHT_BOX,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const ServiceLabel = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.8),
}));

const ServiceRadio = styled(Radio)(({ theme }) => ({
  marginLeft: theme.spacing(0.625),
  marginRight: theme.spacing(0.625),
  width: SIZE_RADIO,
  height: SIZE_RADIO,
}));

const ServiceAvatar = styled(Avatar)(() => ({
  width: SIZE_IMAGE,
  height: SIZE_IMAGE,
}));

export const ServiceCard: React.FC<IServiceCardProps> = ({
  label,
  image,
  checked = false,
  onClick,
  alt = ALT_DEFAULT,
}) => {
  return (
    <ServiceBox onClick={onClick}>
      <ServiceAvatar src={image} alt={alt} />
      <ServiceLabel>{label}</ServiceLabel>
      <ServiceRadio checked={checked} color="secondary"></ServiceRadio>
    </ServiceBox>
  );
};
