import { EventNames } from '@mtt-nails/consts';
import socket from '@mtt-nails/socket-events';
import { Box, CircularProgress } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BookingScreen,
  CreateBookingModal,
  DeleteBookingModal,
  EditBookingModal,
  ListBookingModal,
  MessageModal,
  StaffModal,
  TimeModal,
} from '../../components/templates';
import Layout from '../../components/templates/Layout';
import { useAppStore, useShopStore } from '../../providers/StoreProvider';
import { BookingStore, ModalStore } from './store';

export const BookingPage = observer(() => {
  const appStore = useAppStore();
  const shopStore = useShopStore();
  const { t } = useTranslation();
  const modalStore = useLocalObservable(() => new ModalStore(appStore, shopStore));
  const bookingStore = useLocalObservable(() => new BookingStore(appStore, shopStore));
  useEffect(() => {
    shopStore.fetch();
    socket.registerListener(EventNames.NewBooking, bookingStore.reload);
    socket.registerListener(EventNames.CustomerCheckin, bookingStore.reload);
    socket.registerListener(EventNames.ActivityCancelled, bookingStore.reload);
    return () => {
      socket.unregisterListener(EventNames.NewBooking, bookingStore.reload);
      socket.unregisterListener(EventNames.CustomerCheckin, bookingStore.reload);
      socket.unregisterListener(EventNames.ActivityCancelled, bookingStore.reload);
    };
  }, []);
  if (!bookingStore.ready) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Layout title={t('menu.title.booking')}>
      <BookingScreen
        time={bookingStore.selectedDate}
        modalStore={modalStore}
        bookingStore={bookingStore}
        shopStore={shopStore}
      />
      {modalStore.createModal && (
        <CreateBookingModal
          store={modalStore}
          onSearchCustomer={modalStore.customer?.findCustomerById}
          onCallback={bookingStore.reload}
          bookingStore={bookingStore}
        />
      )}
      {modalStore.deleteModal && <DeleteBookingModal store={modalStore} bookingStore={bookingStore} />}
      {modalStore.editModal && <EditBookingModal store={modalStore} bookingStore={bookingStore} />}
      {modalStore.listModal && <ListBookingModal store={modalStore} bookingStore={bookingStore} />}
      {modalStore.timeModal && <TimeModal store={modalStore} bookingStore={bookingStore} />}
      {modalStore.messageModal && <MessageModal store={modalStore} bookingStore={bookingStore} />}
      {modalStore.staffModal && <StaffModal store={modalStore} bookingStore={bookingStore} />}
    </Layout>
  );
});
