import { operatorApi } from '@mtt-nails/apis/dist/v3';
import { CustomerType } from '@mtt-nails/consts';
import { makeAutoObservable, runInAction } from 'mobx';

import { AppStore } from '../../store/domains/AppStore';
import { ShopStore } from '../../store/domains/ShopStore';

class User {
  firstName = '';
  lastName = '';
  phoneNumber = '';
  email = '';
  birthOfDate = new Date(1990, 0, 1);
  phone = {
    dialing_code: '49',
    number: '',
  };
  customerCode = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setFirstName = (firstName: string) => (this.firstName = firstName);
  setLastName = (lastName: string) => (this.lastName = lastName);
  setPhoneNumber = (phoneNumber: string) => (this.phoneNumber = phoneNumber);
  setEmail = (email: string) => (this.email = email);
  setBirthOfDate = (birthOfDate: Date) => (this.birthOfDate = birthOfDate);
  setCustomerCode = (customerCode: number) => (this.customerCode = customerCode);

  get getCustomerCode() {
    return this.customerCode;
  }
}

export class Store {
  shopId: string;
  user: User;
  isSubmitting = false;

  constructor(private appStore: AppStore, shopStore: ShopStore) {
    makeAutoObservable(this);
    this.shopId = shopStore.shopId;
    this.user = new User();
  }

  submit = async (
    firstName: string,
    lastName: string,
    email?: string,
    birthOfDate?: Date,
    phone?: any,
    customerType?: CustomerType,
  ) => {
    try {
      this.isSubmitting = true;
      const { data } = await operatorApi.createCustomer({
        firstName,
        lastName,
        email,
        birthOfDate,
        shopId: this.shopId,
        phone: { dialing_code: `+${phone.dialing_code}`, number: phone.number },
        customerType,
      });
      this.user.setCustomerCode(data.customerCode);
    } catch (error) {
      this.appStore.notification?.setError(error);
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  };
}
