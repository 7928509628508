import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const Wrapper = styled(Box)(() => ({
  background: 'rgba(0,0,0,0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Loading: React.FC = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
};
