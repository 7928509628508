import { Operator } from '@mtt-nails/apis/dist/v3';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE } from '../../../libs/constants';
import { Button, Input } from '../../atom';
import { BookingNumber } from '../../molecules/booking-number';

interface IItemServingCustomerProps {
  id: string;
  customerCode: string;
  customerName?: string;
  bookNumber: Date | string;
  currentTable: number;
  services?: Operator.Service.Serving[];
  remainTime: number;
  onClickFinish: (id: string) => void;
  onChangeCustomerCode: (activityId: string, currentCode: string, newCode: string) => void;
}

const RemainTimeTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  paddingLeft: theme.spacing(1),
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default}`,
}));

export const ItemServingCustomer: React.FC<IItemServingCustomerProps> = ({
  id,
  customerCode,
  customerName,
  bookNumber,
  remainTime,
  currentTable,
  services,
  onClickFinish,
  onChangeCustomerCode,
}) => {
  const { t } = useTranslation();
  const [codeValue, setCodeValue] = useState(customerCode);

  useEffect(() => {
    if (customerCode) {
      setCodeValue(customerCode);
    }
  }, [customerCode]);

  return (
    <ContentContainer
      p={1}
      display="flex"
      borderRadius={1}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box width="30%">
        <BookingNumber time={bookNumber} />
      </Box>
      <Box width="20%">
        <Box paddingY={0.5} marginY="1px">
          {customerName}
        </Box>
        <Box paddingY={0.5} marginY="1px">
          <Input
            value={codeValue}
            onChange={(e) => setCodeValue(e.target.value)}
            inputProps={{
              onBlur: () => onChangeCustomerCode(id, customerCode, codeValue),
            }}
          />
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="left">
          <BrunchDiningIcon />
          {services?.map((service) => (
            <Typography key={service.id} paddingLeft={1}>
              {service.name[DEFAULT_LANGUAGE].slice(5)}
            </Typography>
          ))}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="left" pt={1.5}>
          <CalendarMonthIcon />
          <Typography paddingLeft={1} paddingTop={0.25}>
            {currentTable}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <HourglassBottomIcon />
        <RemainTimeTitle>{remainTime}</RemainTimeTitle>
      </Box>
      <Box width="15%" alignSelf="center" textAlign="right">
        <Button
          label={t('components.organisms.item-serving-customer.finish-button')}
          color="primary"
          onClick={() => onClickFinish(id)}
          variant="contained"
          borderRadius={30}
        />
      </Box>
    </ContentContainer>
  );
};
