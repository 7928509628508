import { CurrencyTypes } from '@mtt-nails/consts';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Box, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { symbolUnit } from '../../../libs/utils';

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.main,
}));

const ImageButton = styled(Box)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  height: '100%',
  padding: theme.spacing(1, 2),
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[100],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export interface IPrice {
  value: string | number;
  currency: CurrencyTypes;
}

export interface IInvoiceDiscount {
  promo: IPrice;
  offer: IPrice;
  onCheckout?: () => void;
}
const InvoiceDiscount: React.FC<IInvoiceDiscount> = ({ promo, offer, onCheckout }) => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item xs={7}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Label>{t('payment.promo')}</Label>
          </Grid>
          <Grid item>
            <Label>
              {promo.value}
              {symbolUnit(promo.currency)}
            </Label>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Label>{t('payment.offer')}</Label>
          </Grid>
          <Grid item>
            <Label>
              {offer.value}
              {symbolUnit(offer.currency)}
            </Label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <ImageButton onClick={onCheckout}>
          <LocalAtmIcon color="secondary" />
        </ImageButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(InvoiceDiscount);
