import { Grid, styled, Typography as TypographyMui } from '@mui/material';
import React from 'react';

const Typography = styled(TypographyMui)(() => ({
  fontSize: 20,
}));

interface IItemTableForm {
  title: string;
  children: React.ReactNode;
}

const ItemTableForm: React.FC<IItemTableForm> = ({ title, children }) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={8}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ItemTableForm;
