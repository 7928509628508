export const baseUrl = '/';
export const home = `${baseUrl}`;
export const login = `${baseUrl}login`;
export const servingCustomer = `${baseUrl}serving-customers`;
export const staffCheckedIn = `${baseUrl}staff-checked-in`;
export const registerCustomer = `${baseUrl}register-customer`;
export const staffCheckedInForm = `${baseUrl}staff-checked-in-form`;
export const payment = `${baseUrl}payment`;
export const setting = `${baseUrl}setting`;
export const dailyIncome = `${baseUrl}daily-income`;
export const bookingUrl = `${baseUrl}booking`;
