import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Dialog, Grid, IconButton, styled, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStore, ModalStore } from '../../../pages/booking-page/store';
import { Button } from '../../atom';

interface IProps {
  store: ModalStore;
  bookingStore: BookingStore;
}

const WrapHeader = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  textAlign: 'center',
  backgroundColor: pink[300],
  color: theme.palette.primary.contrastText,
}));

const WrapHeaderRow = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
}));

export const ListBookingModal: React.FC<IProps> = observer(({ store, bookingStore }) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={store.toggleListModal} open={store.listModal} fullWidth maxWidth="md">
      <Box>
        <WrapHeader>{t('dialog.new-booking.list-booking')}</WrapHeader>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12}>
            <WrapHeaderRow container padding={1.25}>
              <Grid item xs={3}>
                <Typography>{t('dialog.new-booking.customer-code')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{t('component.organisms.customer-checkin-model.customer-name')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{t('dialog.table.staff-nickName')}</Typography>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                <Typography>{t('dialog.table.action')}</Typography>
              </Grid>
            </WrapHeaderRow>
          </Grid>
          {bookingStore.displayListConfirmedActivities.map((item, idx) => {
            return (
              <Grid item xs={12} key={idx}>
                <WrapHeaderRow container padding={1.25}>
                  <Grid item xs={3}>
                    <Typography>{item.customerCode}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{item.customerName}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{item.staff.nickName || t('dialog.booking-screen.not-yet')}</Typography>
                  </Grid>
                  <Grid item xs={3} display="flex" justifyContent="center">
                    <IconButton
                      onClick={() => {
                        bookingStore.setSelectedActivity(item.id);
                        store.toggleListModal();
                        store.toggleEditModal();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        bookingStore.setSelectedActivity(item.id);
                        store.toggleListModal();
                        store.toggleDeleteModal();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </WrapHeaderRow>
              </Grid>
            );
          })}
          <Grid item xs={12} display="flex" justifyContent="center" marginTop={1.25} alignItems="center">
            <Typography mr={1}>{t('dialog.table.des-create')}</Typography>
            <Box ml={1}>
              <Button
                label={t('dialog.new-booking.title')}
                color="primary"
                variant="contained"
                onClick={() => {
                  store.toggleListModal();
                  store.toggleCreateModal();
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
});
