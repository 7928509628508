import 'react-phone-input-2/lib/style.css';

import React from 'react';
import PhoneInput from 'react-phone-input-2';

interface IPhoneNumberInput {
  countryCode: number | string;
  phoneNumber: string;
  onChange: (value: string, countryCode: number) => void;
}

export const PhoneNumberInput: React.FC<IPhoneNumberInput> = ({ countryCode, phoneNumber, onChange }) => {
  return (
    <PhoneInput
      country={countryCode || 'vn'}
      value={phoneNumber}
      onChange={(value, countryCode) => {
        const code = countryCode as any;
        onChange(value, code.dialCode);
      }}
      enableSearch
    />
  );
};
