import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IBookingNumberProps {
  time: Date | string;
}

const BookingNumberBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.grey[100],
}));

const TimeBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: `${theme.palette.background}`,
  color: theme.palette.primary.light,
}));

export const BookingNumber: React.FC<IBookingNumberProps> = ({ time }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <BookingNumberBox paddingY={0.5} textAlign="center">
        {t('components.organisms.item-customer-checkin.booking-number')}
      </BookingNumberBox>
      <TimeBox
        sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
        borderTop={0}
        paddingY={0.5}
        textAlign="center"
      >
        {dayjs(time).format('LTS')}
      </TimeBox>
    </Box>
  );
};
