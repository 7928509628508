import { Operator, operatorApi } from '@mtt-nails/apis/dist/v3';
import { ActivitiesStatus } from '@mtt-nails/consts';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { get } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

import { ICardBookingProps } from '../../components/molecules/card-booking';
import { getTimeRange30m } from '../../libs/utils/convertTime';
import { transformService } from '../../libs/utils/services';
import { AppStore } from '../../store/domains/AppStore';
import { Customer } from '../../store/domains/CustomerStore';
import { ShopStore } from '../../store/domains/ShopStore';

export function formatDate(date: number, time: string) {
  dayjs.extend(customParseFormat);
  return dayjs(`${dayjs(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm');
}

const TIME_BLOCKING = {
  M_60: 60,
  M_30: 30,
};
export interface IImageCategory {
  largeImage?: string;
  mediumImage?: string;
  thumbnail: string;
}

export interface ISubCategory {
  id: string;
  name: { [key: string]: string };
  image: IImageCategory;
}

export class SubCategory {
  name: { [key: string]: string };
  id: string;
  image: IImageCategory;
  isSelected = false;

  constructor(category: ISubCategory) {
    makeAutoObservable(this);
    this.name = category.name;
    this.id = category.id;
    this.image = category.image;
  }

  toggleSelected = () => {
    this.isSelected = !this.isSelected;
  };
}

export class ModalStore {
  shopId: string;
  services: SubCategory[];
  customer: Customer | null;
  createModal = false;
  editModal = false;
  deleteModal = false;
  listModal = false;
  timeModal = false;
  staffModal = false;
  messageModal = false;
  anchorEl: HTMLButtonElement | null = null;
  comment = '';
  bookingHour = '';
  bookingTable = 0;
  time: Date | null = null;

  constructor(private appStore: AppStore, shopStore: ShopStore) {
    makeAutoObservable(this);
    this.services = [];
    this.shopId = shopStore.shopId;
    this.customer = new Customer(appStore, shopStore.shopId);
    this.fetchService();
  }

  onChangeBookingHour = (value: string) => {
    this.bookingHour = value;
  };

  onChangeDate = (date: Date) => {
    this.time = date;
  };

  onChangeTable = (value: number) => {
    this.bookingTable = value;
  };

  handleChangeComment = (value: string) => (this.comment = value);

  resetForm = () => {
    this.services = this.services.map((service) => {
      service.isSelected = false;
      return service;
    });
    this.customer?.reset();
    this.comment = '';
    this.bookingHour = '';
    this.bookingTable = 0;
    this.time = null;
  };

  toggleCreateModal = () => {
    this.createModal = !this.createModal;
    if (!this.createModal) this.resetForm();
  };

  toggleTimeModal = () => {
    this.timeModal = !this.timeModal;
    if (!this.timeModal) this.resetForm();
  };

  toggleMessageModal = () => {
    this.messageModal = !this.messageModal;
    if (!this.messageModal) this.resetForm();
  };

  toggleStaffModal = () => {
    this.staffModal = !this.staffModal;
    if (!this.staffModal) this.resetForm();
  };

  toggleEditModal = () => {
    this.editModal = !this.editModal;
    if (!this.editModal) this.resetForm();
  };

  toggleListModal = () => {
    this.listModal = !this.listModal;
  };

  toggleDeleteModal = () => {
    this.deleteModal = !this.deleteModal;
    if (!this.deleteModal) this.resetForm();
  };

  handlePopover = (e: any) => (this.anchorEl = e.currentTarget);

  handleClosePopover = () => (this.anchorEl = null);

  fetchService = async () => {
    try {
      const response = await operatorApi.getServicesGroupByField(this.shopId, 'category');
      runInAction(() => {
        const services = transformService(response.data);
        this.services = services;
      });
    } catch (error: any) {
      this.appStore.notification?.setError(error);
    }
  };

  get chosenServices() {
    return this.services
      .filter(({ isSelected }) => isSelected)
      .map(({ id, name }) => {
        return {
          id,
          name,
        };
      });
  }

  booking = async (selectedDate: Date, from: string, callback: any, table?: number) => {
    try {
      const expectedTime: any = formatDate(selectedDate.valueOf(), from).format();
      await operatorApi.booking({
        customerCode: this.customer?.customerCode || 0,
        expectedTime,
        table,
        serviceCategories: this.chosenServices as any,
      });
      callback();
      this.toggleCreateModal();
    } catch (e) {
      this.appStore.notification?.setError(e);
    }
  };

  updateSelectedServices = (selectedServices: any[]) => {
    this.services = this.services.map((service) => {
      let flag = false;
      selectedServices.forEach((selected) => {
        if (service.id === selected.id) flag = true;
      });
      service.isSelected = flag;
      return service;
    });
  };
}

export class BookingStore {
  ready = false;
  bookingList: Operator.Activity[] = [];
  confirmedList: Operator.Activity[] = [];
  staffCols: any[] = [];
  shopCol: any[] = [];
  searchValue = '';
  selectedDate: Date = new Date();
  selectedActivity: any;
  selectedInterval: any = {
    from: '',
    to: '',
    available: false,
    staff: {},
  };
  customers: Operator.Customer.Short[] = [];
  listBooking: ICardBookingProps[] = [];

  constructor(private appStore: AppStore, private shopStore: ShopStore) {
    makeAutoObservable(this);
    this.reload();
  }

  changeDate = (e: Date) => {
    this.selectedDate = e;
    this.reload();
  };

  changeSearch = (value: string) => (this.searchValue = value);

  getBookingList = async () => {
    try {
      const { data } = await operatorApi.getActivities({
        status: [ActivitiesStatus.Booking, ActivitiesStatus.Confirmed],
      });
      runInAction(() => {
        this.bookingList = data.filter((act) => act.status === ActivitiesStatus.Booking);
        this.confirmedList = data.filter((act) => act.status === ActivitiesStatus.Confirmed);
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  getCustomerList = async () => {
    try {
      const { data } = await operatorApi.getCustomers();
      runInAction(() => (this.customers = data));
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };

  handleOpenMenuTrigger = (time: any, staff?: any) => {
    this.setSelectedInterval(time, staff);
  };

  setSelectedInterval = (item: any, staff?: any) => {
    this.selectedInterval.from = item.from;
    this.selectedInterval.to = item.to;
    this.selectedInterval.available = item.available;
    this.selectedInterval.staff = staff;
    if (!item.available || !staff) {
      this.selectedActivity = {};
    } else {
      this.selectedActivity = this.getActivityInStaffSelectedTimeRange();
    }
  };

  getActivityInStaffSelectedTimeRange = () => {
    const selectedConfirmedActivity = this.confirmedList.find((activity) => {
      const expectedTime = dayjs(get(activity, 'trackedTimes.expectedTime'));
      const from = formatDate(this.selectedDate.valueOf(), this.selectedInterval.from);
      const to = formatDate(this.selectedDate.valueOf(), this.selectedInterval.to);

      return (
        (expectedTime.isSameOrAfter(from) ||
          expectedTime
            .add(this.selectedInterval.staff.timeBlocking - this.shopStore.openingTimes?.minTimeTurn, 'm')
            .isSameOrAfter(from)) &&
        expectedTime.isBefore(to) &&
        get(activity, 'staff.userId') === this.selectedInterval.staff.userId
      );
    });
    return selectedConfirmedActivity;
  };

  get displayStaffCols() {
    let displayStaffCols = [];
    if (this.searchValue === '' || !this.searchValue) {
      displayStaffCols = this.staffCols.filter(
        (staffCol) =>
          !get(
            this.shopStore.staffs.find((staff) => staffCol.staff.userId === staff.userId),
            'isHidden',
            false,
          ),
      );
    } else {
      displayStaffCols = this.staffCols.filter(
        (staffCol) => !!staffCol.staff.nickName.includes(toJS(this.searchValue)),
      );
    }

    return displayStaffCols;
  }

  reload = async () => {
    try {
      await this.getBookingList();
      this.getCustomerList();
      this.generateRangeTimesBooking();
      runInAction(() => (this.ready = true));
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };

  setIsFullBookingBaseOnAvailableStaffs = (shopCol: any[]) => {
    return shopCol.map((item, index) => {
      const availableStaffs = this.staffCols.filter((element) => element.col[index].available);
      item.isFullBooking = availableStaffs.length === 0;
      return item;
    });
  };

  generateRangeTimesBooking = () => {
    dayjs.extend(isSameOrAfter);
    const blockTimes = getTimeRange30m(
      formatDate(this.selectedDate.valueOf(), this.shopStore.openingTimes?.open || '8:00'),
      formatDate(this.selectedDate.valueOf(), this.shopStore.openingTimes?.close || '20:00'),
      this.shopStore.openingTimes?.minTimeTurn || 0,
      dayjs(this.selectedDate),
    );
    this.shopCol = blockTimes;
    this.staffCols = this.shopStore.staffs.map((staff) => ({
      staff,
      col: blockTimes,
    }));
    const expectedBookings = this.confirmedList.map((act) => {
      return {
        expectedTime: act.trackedTimes.expectedTime,
        staff: act.staff && this.shopStore.staffs.find((st) => st.userId === act.staff.userId),
      };
    });
    this.staffCols.map((staffCol) => {
      const expectedBookingsOneStaff = expectedBookings.filter(
        (item) => item.staff && item.staff.userId === staffCol.staff.userId,
      );
      const timeTurn = staffCol.staff.timeBlocking;
      staffCol.col.map((block: any) => {
        const from = formatDate(this.selectedDate.valueOf(), block.from);
        const to = formatDate(this.selectedDate.valueOf(), block.to);
        const notAvailable = expectedBookingsOneStaff.some((item) => {
          const available =
            (dayjs(item.expectedTime).isSameOrAfter(from) ||
              dayjs(item.expectedTime)
                .add(timeTurn - this.shopStore.openingTimes?.minTimeTurn || 0, 'm')
                .isSameOrAfter(from)) &&
            dayjs(item.expectedTime).isBefore(to);

          if (available) {
            if (
              dayjs(item.expectedTime)
                .add(timeTurn - this.shopStore.openingTimes?.minTimeTurn || 0, 'm')
                .isAfter(from)
            ) {
              block.merge = true;
            }
          }
          return available;
        });
        if (notAvailable) {
          block.available = true;
        }
        return block;
      });
    });

    this.shopCol.map((block, i) => {
      const from = formatDate(this.selectedDate.valueOf(), block.from);
      const to = formatDate(this.selectedDate.valueOf(), block.to);
      const expectedBookingsOneBlock = expectedBookings.find(
        (item) => dayjs(item.expectedTime).isSameOrAfter(from) && dayjs(item.expectedTime).isBefore(to),
      );
      if (expectedBookingsOneBlock) {
        block.available = true;
      } else {
        const expectedBookingsOneBlockBefore = expectedBookings.filter(
          (item) =>
            dayjs(item.expectedTime)
              .add(this.shopStore.openingTimes?.minTimeTurn || 0, 'm')
              .isSameOrAfter(from) &&
            dayjs(item.expectedTime)
              .add(this.shopStore.openingTimes?.minTimeTurn || 0, 'm')
              .isBefore(to),
        );
        if (
          i > 0 &&
          !this.shopCol[i - 1].available &&
          expectedBookingsOneBlockBefore.length > 0 &&
          expectedBookingsOneBlockBefore.some((item) => item.staff && item.staff.timeBlocking === TIME_BLOCKING.M_60)
        ) {
          block.available = true;
        }
      }
      return block;
    });
    this.shopCol = this.setIsFullBookingBaseOnAvailableStaffs(this.shopCol);
  };

  editConfirmedBooking = async ({ activityId, expectedTime, table, serviceCategories }: any) => {
    await this.editBookingTime(activityId, expectedTime);
    await this.editBookingStaff(activityId, table);
    await this.editBookingService(activityId, serviceCategories);
    this.reload();
  };

  editBookingTime = async (id: string, expectedTime: Date) => {
    try {
      const activityId = id;
      const editedBooking = await operatorApi.editBooking(activityId, {
        expectedTime,
      });
      runInAction(() => {
        if (editedBooking.data.status === ActivitiesStatus.Booking) {
          const idx = this.bookingList.findIndex((booking) => booking.id === activityId);
          this.bookingList[idx] = editedBooking.data;
        } else {
          this.reload();
        }
      });
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };
  editBookingStaff = async (activityId: string, table: number) => {
    try {
      const editedBooking = await operatorApi.editBooking(activityId, {
        table,
      });
      runInAction(() => {
        const idx = this.bookingList.findIndex((booking) => booking.id === activityId);
        this.bookingList[idx] = editedBooking.data;
      });
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };
  editBookingService = async (activityId: string, serviceCategories: any[]) => {
    try {
      await operatorApi.editBooking(activityId, {
        serviceCategories,
      });
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };

  cancelConfirmedBooking = async (activityId: string, message = 'No thing') => {
    try {
      await operatorApi.cancelBooking(activityId, message);
      runInAction(() => {
        this.confirmedList = this.confirmedList.filter((item) => item.id !== activityId);
        this.reload();
      });
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };

  get displayListConfirmedActivities() {
    const customers: any = {};
    this.customers.forEach((item) => {
      customers[item.customerCode] = item.name;
    });

    const confirmedListWithCustomerName = this.confirmedList.map((item) => ({
      ...item,
      customerName: customers[item.customerCode],
    }));

    const liststaffTimeBlocking60 = this.shopStore.staffs.filter((item) => item.timeBlocking === TIME_BLOCKING.M_60);

    return confirmedListWithCustomerName.filter((confirmedActivity) => {
      const hasStaffTimeBlocking60 =
        confirmedActivity.staff &&
        liststaffTimeBlocking60.some((item) => item.userId === confirmedActivity.staff.userId);

      const expectedTime = dayjs(get(confirmedActivity, 'trackedTimes.expectedTime'));

      const from = formatDate(this.selectedDate.valueOf(), this.selectedInterval.from);
      const to = formatDate(this.selectedDate.valueOf(), this.selectedInterval.to);

      return (
        (expectedTime.isSameOrAfter(from) ||
          (hasStaffTimeBlocking60 &&
            expectedTime
              .add(TIME_BLOCKING.M_60 - this.shopStore.openingTimes?.minTimeTurn || 0, 'm')
              .isSameOrAfter(from))) &&
        expectedTime.isBefore(to)
      );
    });
  }

  setSelectedActivity = async (activityId: string) => {
    this.selectedActivity =
      this.confirmedList.find((booking) => booking.id === activityId) ||
      this.bookingList.find((booking) => booking.id === activityId) ||
      {};
  };

  convertBookingList = async () => {
    try {
      const _bookingList = this.bookingList.map(async (booking) => {
        const { data } = await operatorApi.getCustomerByCode({
          shopId: booking.shopId,
          customerCode: +booking.customerCode,
        });
        return {
          id: booking.id,
          src: data.image,
          name: data.name,
          time: booking.trackedTimes.expectedTime,
          staff: booking.staff,
        };
      });
      const _data = await Promise.all(_bookingList);
      runInAction(() => {
        this.listBooking = _data as any[];
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  confirmPendingBooking = async (id: string) => {
    try {
      await operatorApi.confirmBookingCustomer(id);
      this.reload();
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };

  cancelPendingBooking = async (activityId: string, message = 'No thing') => {
    try {
      await operatorApi.cancelBooking(activityId, message);
      runInAction(() => {
        this.bookingList = this.bookingList.filter((item) => item.id !== activityId);
        this.reload();
      });
    } catch (err) {
      this.appStore.notification?.setError(err);
    }
  };

  onConfirmEditTime = async (date: number, time: string, cb: () => void) => {
    const _expectedTime = new Date(formatDate(date, time).format());
    await this.editBookingTime(this.selectedActivity.id, _expectedTime);
    this.listBooking = this.listBooking.map((item) => {
      if (item.id === this.selectedActivity.id) return { ...item, time: _expectedTime };
      return item;
    });
    cb();
  };

  onConfirmEditStaff = async (table: number, cb: () => void) => {
    this.editBookingStaff(this.selectedActivity.id, table);
    const _staff = this.shopStore.staffs.find((item) => item.table === table);
    this.listBooking = this.listBooking.map((item) => {
      if (item.id === this.selectedActivity.id) return { ...item, staff: _staff };
      return item;
    });
    cb();
  };

  onConfirmMessage = (message: string, cb: () => void) => {
    this.listBooking = this.listBooking.filter((item) => item.id !== this.selectedActivity.id);
    this.cancelPendingBooking(this.selectedActivity.id, message);
    cb();
  };
}
