import { Operator, operatorApi } from '@mtt-nails/apis/dist/v3';
import { ActivitiesStatus } from '@mtt-nails/consts';
import { makeAutoObservable, runInAction } from 'mobx';

import { transformActivities } from '../../libs/utils/activities';
import { AppStore } from '../../store/domains/AppStore';
import { Customer } from '../../store/domains/CustomerStore';
import { ShopStore } from '../../store/domains/ShopStore';
import { Activity } from '../home/store';

export class Store {
  shopId: string;
  customer: Customer | null;
  servingCustomerList: Activity[];
  currentActivity: string;
  customerInfoList: Operator.Customer.Short[];
  currentCustomerCode: string;
  newCustomerCode: string;
  newCustomerName: string;
  needConfirmChangeCode: boolean;

  constructor(private appStore: AppStore, shopStore: ShopStore) {
    makeAutoObservable(this);
    this.shopId = shopStore.shopId;
    this.servingCustomerList = [];
    this.customerInfoList = [];
    this.currentActivity = '';
    this.currentCustomerCode = '';
    this.newCustomerCode = '';
    this.newCustomerName = '';
    this.needConfirmChangeCode = false;
    this.customer = new Customer(appStore, shopStore.shopId);
    this.fetchCustomers();
    this.fetchActivities();
  }

  fetchCustomers = async () => {
    try {
      const { data } = await operatorApi.getCustomers();

      runInAction(() => (this.customerInfoList = data));
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchActivities = async () => {
    try {
      const { data } = await operatorApi.getActivities({
        status: [ActivitiesStatus.Doing],
      });

      runInAction(() => {
        const activities = transformActivities(data);
        this.servingCustomerList = activities;

        const customers: any = {};

        this.customerInfoList.forEach((item) => {
          customers[item.customerCode] = item.name;
        });
        this.servingCustomerList.map((item) => {
          item.customerName = customers[item.customerCode];
          return item;
        });
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  getCustomerByCode = async (activityId: string, customerCode: string, newCustomerCode: string) => {
    if (!this.shopId || !customerCode || customerCode === newCustomerCode) {
      return;
    }

    this.currentCustomerCode = customerCode;
    this.currentActivity = activityId;
    const activityIndex = this.servingCustomerList.findIndex((obj) => obj.id === this.currentActivity);

    this.servingCustomerList[activityIndex].customerCode = '';
    try {
      const { data } = await operatorApi.getCustomerByCode({
        shopId: this.shopId,
        customerCode: +newCustomerCode,
      });

      runInAction(() => {
        this.newCustomerCode = newCustomerCode;
        this.newCustomerName = data.name;
        this.needConfirmChangeCode = true;
      });
    } catch (error) {
      const activityIndex = this.servingCustomerList.findIndex((obj) => obj.id === this.currentActivity);

      this.servingCustomerList[activityIndex].customerCode = customerCode;
      this.appStore.notification?.setError(error);
    }
  };

  applyNewCode = async () => {
    this.needConfirmChangeCode = false;
    try {
      await operatorApi.changeCode(this.currentActivity, Number(this.newCustomerCode));

      runInAction(() => {
        const activityIndex = this.servingCustomerList.findIndex((obj) => obj.id === this.currentActivity);
        this.servingCustomerList[activityIndex].customerCode = this.newCustomerCode;
        this.servingCustomerList[activityIndex].customerName = this.newCustomerName;
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  rejectNewCode = () => {
    this.needConfirmChangeCode = false;

    runInAction(() => {
      const activityIndex = this.servingCustomerList.findIndex((obj) => obj.id === this.currentActivity);
      this.servingCustomerList[activityIndex].customerCode = this.currentCustomerCode;
    });
  };

  endActivity = async (activityId: string) => {
    try {
      await operatorApi.endActivity(activityId);
      runInAction(() => {
        this.servingCustomerList = this.servingCustomerList.filter((item) => item.id !== activityId);
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetch = async () => {
    await this.fetchCustomers();
    this.fetchActivities();
  };

  toggleFormChangeCustomerCode = (value: boolean) => {
    this.needConfirmChangeCode = value;
  };
}
