import { operatorApi } from '@mtt-nails/apis/dist/v3';
import { makeAutoObservable, runInAction } from 'mobx';

import i18n from '../../languages/i18n';
import { AuthStore } from '../../store/domains/AuthStore';
import { StorageStore } from '../../store/domains/StorageStore';

class ChangePassword {
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';

  constructor() {
    makeAutoObservable(this);
  }

  setOldPassword = (oldPassword: string) => (this.oldPassword = oldPassword);
  setNewPassword = (newPassword: string) => (this.newPassword = newPassword);
  setConfirmPassword = (confirmPassword: string) => (this.confirmPassword = confirmPassword);
}

export class Store {
  changePassword: ChangePassword;
  isSubmitting = false;

  constructor(private authStore: AuthStore, public storageStore: StorageStore) {
    makeAutoObservable(this);
    this.changePassword = new ChangePassword();
  }

  submit = async (oldPassword: string, newPassword: string, confirmPassword: string): Promise<void> => {
    try {
      this.isSubmitting = true;
      if (confirmPassword !== newPassword) {
        this.authStore.root.appStore.notification?.setError(i18n.t('app.notification.setting.password-do-not-match'));
      } else {
        await operatorApi.changePassword({
          new_password: newPassword,
          current_password: oldPassword,
        });
        this.authStore.root.appStore.notification?.setSuccess(
          i18n.t('app.notification.setting.change-password-successfully'),
        );
      }
    } catch (error) {
      this.authStore.root.appStore.notification?.setError(error);
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  };

  changeLanguage = (language: string): void => {
    this.storageStore.setDefaultLanguage(language);
  };

  getDefaultLanguage = (): string => {
    return this.storageStore.getDefaultLanguage() as string;
  };
}
