import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalStore } from '../../../pages/booking-page/store';
import { CustomPopover } from '../../atom';

export interface IBookingTableProps {
  rows: any[];
  background?: string;
  idleColor?: string;
  bookedColor?: string;
  nameOwner: string;
  timeRange: any[];
  modalStore: ModalStore;
  handleOpenMenuTrigger: (time: any, staff?: any) => void;
}

const WIDTH_POPOVER = '200px';

const WrapBox = styled(Box)<{ background: string }>(({ background }) => ({
  maxHeight: 'calc(100vh - 280px)',
  overflowY: 'hidden',
  '&:hover': {
    overflowY: 'overlay',
  },
  width: '100%',
  background,
}));

export const BookingTable: React.FC<IBookingTableProps> = observer(
  ({
    rows,
    background = 'white',
    idleColor = 'green',
    bookedColor = 'red',
    nameOwner,
    timeRange,
    modalStore,
    handleOpenMenuTrigger,
  }) => {
    const { t } = useTranslation();

    const open = Boolean(modalStore.anchorEl);

    const id = open ? 'simple-popover' : undefined;

    const handleTimeSelected = (timeRange: any[], rows: any[], idRow: number) => {
      let flag;
      let step = 0;
      const _column = rows.slice(0, idRow).map((data: any, idx: number) => {
        return { ...data, idx };
      });
      _column.reverse();
      while (step < _column.length) {
        if (_column[step]?.merge) {
          flag = _column[step];
          step++;
        } else break;
      }
      if (step) return timeRange[flag.idx];
      return timeRange[idRow];
    };

    return (
      <>
        <WrapBox background={background}>
          <Grid container>
            <Grid container>
              <Grid item xs>
                <Typography fontWeight={550} textAlign="center">
                  {nameOwner}
                </Typography>
              </Grid>
              {rows.map((el) => (
                <Grid item xs key={el.staff.userId}>
                  <Typography
                    fontWeight={550}
                    textAlign="center"
                  >{`${el.staff.nickName}(${el.staff.timeBlocking})`}</Typography>
                </Grid>
              ))}
            </Grid>
            {timeRange.map((time, idRow) => {
              return (
                <Grid container key={idRow}>
                  <Grid
                    item
                    xs
                    onClick={
                      time.available
                        ? () => {
                            handleOpenMenuTrigger(time);
                            modalStore.toggleListModal();
                          }
                        : () => {
                            handleOpenMenuTrigger(time);
                            modalStore.toggleCreateModal();
                          }
                    }
                    sx={{
                      height: '60px',
                      background: time.available ? bookedColor : idleColor,
                      border: `1px solid ${background}`,
                      alignItems: 'center',
                      display: 'flex',
                      color: 'white',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {`${time.from}-${time.to}`}
                  </Grid>
                  {rows.map((column) => {
                    return (
                      <Grid
                        item
                        aria-describedby={id}
                        xs
                        key={`${column.staff.userId}-${idRow}`}
                        sx={{
                          height: '60px',
                          background: column.col[idRow].available ? bookedColor : idleColor,
                          border: `1px solid ${background}`,
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          if (column.col[idRow].available) modalStore.handlePopover(e);
                          else modalStore.toggleCreateModal();
                          handleOpenMenuTrigger(handleTimeSelected(timeRange, column.col, idRow), column.staff);
                        }}
                      />
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </WrapBox>
        <CustomPopover anchorEl={modalStore.anchorEl} handleClose={modalStore.handleClosePopover}>
          <Box width={WIDTH_POPOVER}>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={modalStore.toggleEditModal}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('booking.table.edit')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={modalStore.toggleDeleteModal}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('booking.table.delete')} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </CustomPopover>
      </>
    );
  },
);
