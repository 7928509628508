import { EventNames } from '@mtt-nails/consts';
import socket from '@mtt-nails/socket-events';
import { Dialog } from '@mui/material';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ChangeTableForm from '../../components/organisms/change-table-form';
import DeleteActivityForm from '../../components/organisms/delete-activity-form';
import Layout from '../../components/templates/Layout';
import WaitingListCustomer from '../../components/templates/waiting-list-customer';
import { useAppStore, useShopStore } from '../../providers/StoreProvider';
import { paths } from '../../routers';
import CheckinModel from './ChekinModel';
import { Store } from './store';
import { TWaitingListCustomer } from './types';

export const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentActivity, setCurrentActivity] = useState<TWaitingListCustomer>();
  const appStore = useAppStore();
  const shopStore = useShopStore();
  const store = useLocalObservable(() => new Store(appStore, shopStore));
  const loadData = () => {
    shopStore.fetch();
    store.fetch();
  };
  useEffect(() => {
    loadData();
    socket.registerListener(EventNames.NewBooking, loadData);
    socket.registerListener(EventNames.ActivityStarted, loadData);
    socket.registerListener(EventNames.CustomerCheckin, loadData);
    socket.registerListener(EventNames.ActivityReady, loadData);
    socket.registerListener(EventNames.ActivityCancelled, loadData);
    return () => {
      socket.unregisterListener(EventNames.NewBooking, loadData);
      socket.unregisterListener(EventNames.ActivityStarted, loadData);
      socket.unregisterListener(EventNames.CustomerCheckin, loadData);
      socket.unregisterListener(EventNames.ActivityReady, loadData);
      socket.unregisterListener(EventNames.ActivityCancelled, loadData);
    };
  }, []);

  const handleChangeTable = (
    activityId: string,
    bookNumber: string | Date,
    customerCode: string,
    currentTable: number,
    customerName: string,
  ) => {
    setCurrentActivity({ activityId, bookNumber, customerCode, currentTable, customerName });
    store.toggleFormChangeTable(true);
  };

  const handleClickDelete = (
    activityId: string,
    bookNumber: string | Date,
    customerCode: string,
    currentTable: number,
    customerName: string,
  ) => {
    setCurrentActivity({ activityId, bookNumber, customerCode, currentTable, customerName });
    store.toggleFormDeleteActivity(true);
  };

  return (
    <Layout title={t('menu.title.customer-waiting-list')}>
      <Observer>
        {() => (
          <>
            <WaitingListCustomer
              store={store}
              onClickChangeTable={handleChangeTable}
              onClickDeleleItem={handleClickDelete}
              onClickStartService={store.startService}
              onClickGetNumberForCustomer={() => store.toggleFormCheckin(true)}
              onClickRegisterForCustomer={() => navigate(paths.registerCustomer)}
            />
            <Dialog
              onClose={() => store.toggleFormCheckin(false)}
              open={store.isFormCheckinShown}
              maxWidth="lg"
              fullWidth
            >
              <CheckinModel onCancel={() => store.toggleFormCheckin(false)} store={store} />
            </Dialog>
            {currentActivity && (
              <Dialog
                onClose={() => store.toggleFormChangeTable(false)}
                open={store.isFormChangeTableShown}
                maxWidth="lg"
                fullWidth
              >
                <ChangeTableForm
                  staffs={store.staffs}
                  handleConfirmChangeTable={store.confirmChangeTable}
                  activity={currentActivity}
                  onCancel={() => store.toggleFormChangeTable(false)}
                />
              </Dialog>
            )}
            {currentActivity && (
              <Dialog
                onClose={() => store.toggleFormDeleteActivity(false)}
                open={store.isFormDeleteActivityShown}
                maxWidth="lg"
                fullWidth
              >
                <DeleteActivityForm
                  handleConfirmDeleteActivity={store.confirmDeleteActivity}
                  activity={currentActivity}
                  onCancel={() => store.toggleFormDeleteActivity(false)}
                />
              </Dialog>
            )}
          </>
        )}
      </Observer>
    </Layout>
  );
};
