import { Operator } from '@mtt-nails/apis/dist/v3';

import { Activity } from '../../pages/home/store';

export const transformActivities = (list: Operator.Activity[]) => {
  const data: Activity[] = [];
  list.map((listItem) => {
    data.push(new Activity(listItem));
  });
  return data;
};
