import { Operator, operatorApi } from '@mtt-nails/apis/dist/v3';
import { CurrencyTypes } from '@mtt-nails/consts';
import { makeAutoObservable, runInAction } from 'mobx';

import i18n from '../../languages/i18n';
import { AppStore } from '../../store/domains/AppStore';
import { Customer } from '../../store/domains/CustomerStore';
import { ShopStore } from '../../store/domains/ShopStore';

export class ServiceDetail {
  id: string;
  name: { [key: string]: string };
  price: number;

  constructor(id: string, name: { [key: string]: string }, price: number) {
    makeAutoObservable(this);
    this.id = id;
    this.name = name;
    this.price = price;
  }
}

export class Services {
  id: string;
  services: ServiceDetail[] = [];
  color: string;
  displayOnRow: number;
  name: { [key: string]: string };
  constructor(
    id: string,
    name: { [key: string]: string },
    services: ServiceDetail[],
    color: string,
    displayOnRow: number,
  ) {
    makeAutoObservable(this);
    this.id = id;
    this.services = services;
    this.name = name;
    this.color = color;
    this.displayOnRow = displayOnRow;
  }
}

export class Store {
  services: Services[] = [];
  invoiceList: Operator.Invoice[] = [];
  invoice: Operator.Invoice | null = null;
  promotions: Operator.Promotion.Detail[] = [];
  customer: Customer | null = null;

  isShowInvoice?: boolean = false;
  isShowPromo?: boolean = false;
  isShowOffer?: boolean = false;

  constructor(private appStore: AppStore, private shopStore: ShopStore) {
    makeAutoObservable(this);
    this.fetchServices();
    this.fetchInvoiceList();
    this.fetchPromotions();
  }

  getCustomerInfoByCode = async () => {
    if (!this?.invoice?.customerCode) {
      runInAction(() => (this.customer = null));
      return;
    }
    try {
      const customer = new Customer(this.appStore, this.shopStore.shopId);
      customer.findCustomerById(String(this.invoice.customerCode));
      runInAction(() => {
        this.customer = customer;
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchServices = async () => {
    try {
      const { data } = await operatorApi.getServicesGroupByField(this.shopStore.shopId, 'category');
      let listService: any[] = [];
      data.forEach(({ items }) => {
        listService = listService.concat(items);
      });
      runInAction(
        () =>
          (this.services = listService.map(
            ({ id, configuration: { color, displayOnRow }, services, name }) =>
              new Services(id, name, services, color, displayOnRow),
          )),
      );
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchInvoiceList = async () => {
    try {
      const { data } = await operatorApi.getInvoices();
      runInAction(() => {
        this.invoiceList = data;
        this.invoice = data[0];
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  fetchPromotions = async () => {
    try {
      const { data } = await operatorApi.getPromotions({ shopId: this.shopStore.shopId });
      data.length > 0 && runInAction(() => (this.promotions = data));
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  removeInvoicePromotion = async () => {
    if (!this.invoice) {
      return this.appStore.notification?.setInfo(i18n.t('payment.error.no-invoice'));
    }
    const { promotion } = this.invoice;
    try {
      const { data } = await operatorApi.removeInvoicePromotion(this.invoice.id, promotion.id);
      runInAction(() => this.updateInvoiceList(data));
    } catch (error) {
      this.appStore.notification?.setError(error);
    } finally {
      this.toggleShowPromo();
    }
  };

  setInvoicePromotion = async (promotion: Operator.Promotion.Detail) => {
    if (!this.invoice) {
      return this.appStore.notification?.setInfo(i18n.t('payment.error.no-invoice'));
    }
    try {
      const { data } = await operatorApi.updateInvoicePromotion(this.invoice.id, promotion.id);
      runInAction(() => {
        this.updateInvoiceList(data);
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    } finally {
      this.toggleShowPromo();
    }
  };

  setInvoiceOffer = async (offerValue: number) => {
    if (!this.invoice) {
      return this.appStore.notification?.setInfo(i18n.t('payment.error.no-invoice'));
    }
    try {
      const { data } = await operatorApi.updateInvoiceOffer(this.invoice.id, {
        value: Number(offerValue),
      });
      runInAction(() => this.updateInvoiceList(data));
    } catch (error) {
      this.appStore.notification?.setError(error);
    } finally {
      this.toggleShowOffer();
    }
  };

  updateInvoiceList = (invoice: Operator.Invoice) => {
    runInAction(() => {
      this.invoice = invoice;
      this.invoiceList = this.invoiceList.map((item) => {
        return item.id === invoice.id ? invoice : item;
      });
    });
  };

  addServiceToInvoice = async (service: ServiceDetail) => {
    if (!this.invoice) {
      return this.appStore.notification?.setInfo(i18n.t('payment.error.no-invoice'));
    }
    try {
      const { data } = await operatorApi.addInvoiceService(this.invoice.id, {
        id: service.id,
        price: String(service.price),
      });
      runInAction(() => {
        this.updateInvoiceList(data);
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  removeServiceToInvoice = async (service: ServiceDetail) => {
    if (!this.invoice) {
      return this.appStore.notification?.setInfo(i18n.t('payment.error.no-invoice'));
    }
    try {
      const { data } = await operatorApi.removeInvoiceService(this.invoice.id, {
        id: service.id,
        price: String(service.price),
      });
      runInAction(() => {
        this.updateInvoiceList(data);
      });
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  setInvoice = async (invoice: Operator.Invoice) => {
    runInAction(() => (this.invoice = invoice));
  };

  toggleShowInvoice = async () => {
    this.isShowInvoice = !this.isShowInvoice;
    if (this.isShowInvoice) {
      await this.getCustomerInfoByCode();
    }
  };

  toggleShowPromo = () => {
    this.isShowPromo = !this.isShowPromo;
  };

  toggleShowOffer = () => {
    this.isShowOffer = !this.isShowOffer;
  };
  payment = async () => {
    if (!this.invoice) {
      return this.appStore.notification?.setInfo(i18n.t('payment.error.no-invoice'));
    }
    try {
      await operatorApi.endInvoice(this.invoice.id);
      this.toggleShowInvoice();
      this.appStore.notification?.setSuccess('Successful payment');
      runInAction(() => {
        this.customer = null;
        this.invoice = null;
      });
      await this.fetchInvoiceList();
    } catch (error) {
      this.appStore.notification?.setError(error);
    }
  };

  get listPromotions() {
    return this.promotions.map((item) => {
      return {
        id: item.id,
        description: item.discount.desc,
        label: item.description,
        onPromotionClick: () => this.setInvoicePromotion(item),
      };
    });
  }

  get listServices() {
    return this.services.map((item) => {
      return {
        ...item,
        listData: item.services.map((service) => {
          return {
            id: service.id,
            name: service.name,
            onClick: () => this.addServiceToInvoice(service),
          };
        }),
      };
    });
  }

  get serviceOnInvoice() {
    if (!this.invoice) return [];
    return this.invoice?.services.map((service) => {
      return {
        id: service.id,
        serviceName: service.name,
        price: { value: service.price, currency: CurrencyTypes.Euro },
        editQuantity: {
          value: service.amount,
          onIncrease: () => this.addServiceToInvoice(service),
          onDecrease: () => this.removeServiceToInvoice(service),
        },
      };
    });
  }

  get serviceOnPayment() {
    return this.serviceOnInvoice.map((service) => {
      return {
        serviceName: service.serviceName,
        price: service.price.value,
        quantity: service.editQuantity.value,
      };
    });
  }
}
