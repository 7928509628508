import { IconButton, InputProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import * as React from 'react';

export interface IInputProps extends InputProps {
  label?: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  frontIcon?: React.ReactNode;
  frontIconClick?: () => void;
  endIcon?: React.ReactNode;
  endIconClick?: () => void;
  errorMessage?: string;
  variant?: TextFieldProps['variant'];
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(function _Input(props, ref) {
  const {
    label,
    placeholder,
    frontIcon,
    frontIconClick,
    endIcon,
    endIconClick,
    errorMessage,
    value,
    onChange,
    fullWidth,
    name,
    type,
    variant,
    inputProps,
    multiline,
    maxRows,
    minRows,
  } = props;
  return (
    <TextField
      type={type}
      error={!!errorMessage}
      helperText={errorMessage}
      label={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      variant={variant}
      inputProps={inputProps}
      fullWidth={fullWidth}
      multiline={multiline}
      maxRows={maxRows}
      minRows={minRows}
      ref={ref}
      InputProps={{
        startAdornment: frontIcon ? (
          <InputAdornment position="start">
            <IconButton onClick={frontIconClick}>{frontIcon}</IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
        endAdornment: endIcon ? (
          <InputAdornment position="end">
            <IconButton onClick={endIconClick}>{endIcon}</IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
    />
  );
});
