import { EventNames } from '@mtt-nails/consts';
import socket from '@mtt-nails/socket-events';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DailyIncomeContainer from '../../components/templates/daily-income-container';
import Layout from '../../components/templates/Layout';
import { useAppStore, useShopStore } from '../../providers/StoreProvider';
import { Store } from './store';

export const DailyIncomePage: React.FC = () => {
  const { t } = useTranslation();
  const appStore = useAppStore();
  const shopStore = useShopStore();
  const store = useLocalObservable(() => new Store(appStore, shopStore));

  const loadData = () => {
    shopStore.fetch();
    store.fetch();
  };
  useEffect(() => {
    loadData();
    socket.registerListener(EventNames.ActivityPaid, loadData);

    return () => {
      socket.unregisterListener(EventNames.ActivityPaid, loadData);
    };
  }, []);

  return (
    <Layout title={t('menu.title.daily-income')}>
      <Observer>{() => <DailyIncomeContainer store={store} />}</Observer>
    </Layout>
  );
};
